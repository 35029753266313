import React from "react"

import Layout from "../components/layout/Layout"
import ThankYouContent from "../components/thankYou/ThankYouContent"

import { ctaTarget, SeoMetaType, StoryblokImageType } from "../types"

type ThankYouProps = {
  pathname: string,
  origin: string,
  title: string,
  text: string,
  image: StoryblokImageType,
  seoMeta: SeoMetaType,
  ctaButtonLabel: string,
  ctaButtonLink: ctaTarget,
}

function ThankYouPage({ pathname, origin, title, text, image, seoMeta, ctaButtonLabel, ctaButtonLink }: ThankYouProps) {
  return (
    <Layout pathname={pathname} origin={origin} headerFix seoMeta={seoMeta} isThankYou>
      <ThankYouContent title={title} text={text} image={image} ctaButtonLink={ctaButtonLink} ctaButtonLabel={ctaButtonLabel} />
    </Layout>
  )
}

export default ThankYouPage
