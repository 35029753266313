import React, { useCallback } from "react"
import classNames from "classnames"
import { useClickOutsideListenerRef } from "../hooks/useClickOutsideListenerRef"
import SubmenuComponents from "./SubmenuComponents"
import { SubmenuItemType } from "../../../types"

type NavDropdownProps = {
  isExpanded: boolean
  closeDropdown: (e: Event) => void
  items: SubmenuItemType[]
  pathname: string
}

const DesktopNavDropdown = ({
  isExpanded,
  closeDropdown,
  items,
  pathname,
}: NavDropdownProps) => {
  const renderClassNames = () =>
    classNames("topNav__desktopDropdown flex-column", {
      "d-flex": isExpanded,
      "topNav__desktopDropdown--expanded": isExpanded,
    })

  const onClickOutsideCallback = useCallback(
    (e: Event) => {
      if (isExpanded) {
        closeDropdown(e)
      }
    },
    [closeDropdown, isExpanded]
  )

  const ref = useClickOutsideListenerRef(onClickOutsideCallback)

  const menuItems = items?.map((item, i) =>
    React.createElement(SubmenuComponents(item.component), {
      key: i,
      ...item,
      pathname: pathname,
    })
  )

  return (
    <div className={renderClassNames()} ref={ref}>
      <ul className="topNav__desktopDropdownContent">
        {menuItems}
      </ul>
    </div>
  );
}

export default DesktopNavDropdown
