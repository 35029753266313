import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/Layout"
import PositionsHero from "../components/openPositions/PositionsHero"
import PositionsList from "../components/openPositions/PositionsList"
import PositionsConnection from "../components/openPositions/PositionsConnection"
import Testimonials from "../components/shared/Testimonials"

import { getLangFromPathname } from "../utils"

import {
  ctaTarget,
  JobListingType,
  SeoMetaType,
  StoryblokImageType,
  TestimonialType,
  JobDescriptionNodeType,
} from "../types"

type OpenPositionsPageProps = {
  pathname: string
  origin: string
  text: string
  image: StoryblokImageType
  seoMeta: SeoMetaType
  jobListing: Array<JobListingType>
  title: string
  description: string
  ctaButton: string
  ctaTarget: ctaTarget
  background: StoryblokImageType
  titleTestimonial: string
  testimonial: Array<TestimonialType>
}

const sortJobsDesc = (a: JobDescriptionNodeType, b: JobDescriptionNodeType) =>
  a.position && b.position ? a.position - b.position : 0

const useLoadJobDescriptions = (
  activeLanguage: string
): JobDescriptionNodeType[] => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query JobDescriptionOpenPositions {
          allStoryblokEntry(
            filter: { field_component: { eq: "JobDescription" } }
          ) {
            nodes {
              full_slug
              content
              lang
              position
            }
          }
        }
      `
    )

    return queryResult.allStoryblokEntry.nodes
      .map(node => {
        return {
          ...node,
          content: JSON.parse(node.content),
        } as JobDescriptionNodeType
      })
      .filter(q => q.lang === activeLanguage)
      .sort(sortJobsDesc)
  } catch (e) {
    console.log(e)
    console.error(
      "Static Query for Blog Overview failed!!! Falling back to dummy data."
    )
    return []
  }
}

function OpenPositionsPage({
  pathname,
  origin,
  text,
  image,
  seoMeta,
  jobListing,
  title,
  description,
  ctaButton,
  ctaTarget,
  background,
  titleTestimonial,
  testimonial,
}: OpenPositionsPageProps) {
  const activeLanguage = getLangFromPathname(pathname)
  const allJobDescriptions = useLoadJobDescriptions(activeLanguage)
  const hotPositions = allJobDescriptions.filter(job => job.content.hotPosition)
  const otherPositions = allJobDescriptions.filter(
    job => !job.content.hotPosition
  )

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      headerFix
      lightGreyBg
      seoMeta={seoMeta}
    >
      <PositionsHero text={text} image={image} />
      <PositionsList
        hotPositions={hotPositions}
        otherPositions={otherPositions}
      />
      <PositionsConnection
        title={title}
        description={description}
        ctaButton={ctaButton}
        ctaTarget={ctaTarget}
        background={background}
      />
      <Testimonials
        isTestimonial
        headline={titleTestimonial}
        testimonials={testimonial}
      />
    </Layout>
  )
}

export default OpenPositionsPage
