import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import CheckBox from "./formComponents/CheckBox"
import { CommonFormProps } from "../types"
import SubmitButton from "./formComponents/SubmitButton"

const ZOHO_WEBINAR_SUBMIT_URL =
  "https://form.iconity.ai/simplitygroup/form/IconityWebinar1/formperma/eLYN2RGOSmdT7q8nR24VrIrI5HI6qGZUvITpZP5q_8Q/htmlRecords/submit"

type WebinarFormFields = {
  firstName: string
  lastName: string
  email: string
  company: string
  marketing: boolean
}

const zohoClientFormFields: ZohoFormFields<WebinarFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  company: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: false,
  },
  marketing: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
}

export type WebinarFormProps = {
  companyLabel: string
} & CommonFormProps<WebinarFormFields>

const WebinarForm = ({
  titleForm,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  hideNewsletter,
  ...labels
}: WebinarFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<WebinarFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_WEBINAR_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoClientFormFields,
  })

  return (
    <div className="form__container">
      {titleForm && <h3 className="form__headline">{titleForm}</h3>}
      <div className="form__row">
        <TextField
          name="firstName"
          label={labels.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={labels.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <TextField
        name="company"
        label={labels.companyLabel}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={labels.emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={labels.marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <SubmitButton
        isFetching={isFetching}
        doSubmit={doSubmit}
        submitLabel={submitLabel}
        submitError={submitError}
      />
    </div>
  )
}

export default WebinarForm
