import React from 'react'
import { companySocial } from '../../types'
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es'

import GoogleMapComponentWithMarker from './Map'

import FacebookLogo from '../../assets/images/icons/facebook.svg'
import LinkedinLogo from '../../assets/images/icons/linkedin.svg'
import InstagramLogo from '../../assets/images/icons/instagram.svg'

const resolver = new RichTextResolver()

export type GetContactProps = {
  headline: string,
  subHeadline: string,
  companyEmail: string,
  companyPhone: string,
  companyName: string,
  companyInfo: any,
  companyFacebook: companySocial,
  companyLinkedin: companySocial,
  companyInstagram: companySocial,
}

const GetContact = ({
  headline,
  subHeadline,
  companyEmail,
  companyPhone,
  companyName,
  companyInfo,
  companyFacebook,
  companyLinkedin,
  companyInstagram
}: GetContactProps) => {
  const html = companyInfo ? resolver.render(companyInfo) : ''

  return (
    <div className="c-getContact">
      <h1 className="c-getContact__headline d-none d-xl-block">
        {headline}
      </h1>
      <p className="c-getContact__text d-none d-xl-block">
        {subHeadline}
      </p>

      <div className="c-getContact__map">
        <GoogleMapComponentWithMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyADeKoLzSoy6-SMNPtXGFY4ggWAY82Ph6M"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>

      <div className="c-getContact__links d-flex flex-column align-items-start">
        {companyEmail && (
          <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
        )}
        {companyPhone && (
          <a href={`tel:${companyPhone}`}>{companyPhone}</a>
        )}
      </div>

      <div className="c-getContact__info d-flex flex-column align-items-start">
        {companyName && (
          <p className="c-getContact__info-name">
            {companyName}
          </p>
        )}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>

      <div className="c-getContact__socials d-flex">
        {companyFacebook.url && (
          <a href={companyFacebook.url} onClick={(event) => { event.preventDefault(); window.open(companyFacebook.url); }}>
            <FacebookLogo />
          </a>
        )}
        {companyLinkedin.url && (
          <a href={companyLinkedin.url} onClick={(event) => { event.preventDefault(); window.open(companyLinkedin.url); }}>
            <LinkedinLogo />
          </a>
        )}
        {companyInstagram.url && (
          <a href={companyInstagram.url} onClick={(event) => { event.preventDefault(); window.open(companyInstagram.url); }}>
            <InstagramLogo />
          </a>
        )}
      </div>

    </div>
  )
}

export default GetContact
