import React, { useState } from 'react'
import classNames from 'classnames'
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es'
import Accordion from 'react-bootstrap/Accordion'
import FindJobOverlay from './FindJobOverlay'
import StoryblokImage from '../shared/StoryblokImage'
import ArrowRight from '../../assets/images/icons/arrow-right.svg'
import ArrowRightBig from '../../assets/images/icons/arrow-right-big.svg'
import { FindJobCards } from '../../types'

const resolver = new RichTextResolver()

export type FindJobSelectionBoxes = {
  cards: Array<FindJobCards>,
  isAppSelected: boolean,
}

const FindJobSelectionBoxes = ({ cards, isAppSelected }: FindJobSelectionBoxes) => {
  const [overlay, setOverlay] = useState('')
  const [accordionOpened, setAccordionSlide] = useState(null)

  const handleOverlayCloseClick = () => {
    setOverlay('')
  }

  const handleBoxClick = (boxId, isLeftAvailable, isRightAvailable) => {
    const isAvailable = isAppSelected ? isRightAvailable : isLeftAvailable
    isAvailable && setOverlay(boxId)
  }

  const renderBoxClassNames = (isLeftAvailable, isRightAvailable) => (
    classNames({
      'text-center': true,
      'c-findJobSelection__boxItem': true,
      'c-findJobSelection__boxItem--unavailable': isAppSelected ? !isRightAvailable : !isLeftAvailable,
    })
  )

  const renderAccordionClassNames = (i) => (
    classNames({
      'c-findJobSelection__accordion-wrapper': true,
      'c-findJobSelection__accordion-wrapper--selected': i == accordionOpened,
    })
  )

  const renderCards = cards.map((card, i) => {
    return (
      <div className="col-md-4 col-lg-3" key={`card-${i}`}>
        <div
          className={renderBoxClassNames(card.displayOnLeft, card.displayOnRight)}
          onClick={() => handleBoxClick(card._uid, card.displayOnLeft, card.displayOnRight)}
        >
          <div className="c-findJobSelection__boxIconWrapper">
            <StoryblokImage image={card?.iconStandard} className="c-findJobSelection__boxIcon c-findJobSelection__boxIcon-standard" />
            <StoryblokImage image={card?.iconHover} className="c-findJobSelection__boxIcon c-findJobSelection__boxIcon-hover" />
          </div>
          <p className="c-findJobSelection__boxItem-text">{card?.title}</p>
          <div className="c-findJobSelection__boxItem-more d-xl-none">
            <span>
              {card?.tabletMoreLabel}
              <ArrowRight />
            </span>
          </div>
        </div>
      </div>
    )
  })

  const renderAccordion = cards.map(((card, i) => {
    const isShown = isAppSelected ? card?.displayOnRight: card?.displayOnLeft

    if (!isShown) return

    let content
    switch (isAppSelected) {
      case true:
        content = card?.textRight ? resolver.render(card.textRight) : ''
        break;
      default:
        content = card?.textLeft ? resolver.render(card.textLeft) : ''
        break;
    }

    return (
      <div className={renderAccordionClassNames(i)} key={`card-accordion-${i}`}>
        <Accordion.Toggle eventKey={`${i}`} className="c-findJobSelection__accordion-toggle">
          <div className="c-findJobSelection__accordion-images">
            <StoryblokImage image={card?.iconStandard} className="c-findJobSelection__accordion-icon" />
            <StoryblokImage image={card?.iconHover} className="c-findJobSelection__accordion-icon" />
          </div>
          <p className="mb-0 text-left">
            {card?.title}
          </p>
          <ArrowRightBig className="c-findJobSelection__accordion-openIcon" />
          <div className="c-findJobSelection__accordion-close" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`${i}`} className="c-findJobSelection__accordion-collapse">
          <>
            <div dangerouslySetInnerHTML={{__html: content}} />
            <a href={card?.ctaLink.cached_url} className="c-findJobSelection__accordion-link btnPrimary btnPrimary--fullWidth">
              {card?.ctaButton}
            </a>
          </>
        </Accordion.Collapse>
      </div>
    )
  }))

  return (
    <div className="c-findJobSelection__boxes">
      <FindJobOverlay close={handleOverlayCloseClick} content={cards.filter(item => item._uid === overlay)} />

      <div className="row d-none d-md-flex">
        {renderCards}
      </div>

      <div className="row d-md-none">
        <Accordion activeKey={accordionOpened} className="c-findJobSelection__accordion" onSelect={(e) => setAccordionSlide(e)}>
          {renderAccordion}
        </Accordion>
      </div>
    </div>
  )
}

export default FindJobSelectionBoxes
