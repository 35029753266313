import React from "react"
import { SubmenuItemType } from "../../../types"

type SubmenuHeaderProps = SubmenuItemType;

const SubmenuHeader = ({ label, link }: SubmenuHeaderProps) => {
  return (
    <li className="topNav__subMenuItem topNav__subMenuItem-header">
      <a href={link?.cached_url} className="hoverUnderlineAnimation">
        {label}
      </a>
    </li>
  )
}

export default SubmenuHeader
