import React from "react"

import Layout from '../components/layout/Layout'
import ContactUsHero from '../components/contactUs/ContactUsHero'
import ContactUsForm from '../components/contactUs/ContactUsForm'

import { SeoMetaType, PersonalIntroType, StoryblokImageType } from "../types"
import { ClientFormProps } from '../zohoForms/ClientForm'

type ContactUsPage = {
  pathname: string,
  origin: string,
  seoMeta: SeoMetaType,
  pageTitle: string,
  personalIntro: Array<PersonalIntroType>,
  image: StoryblokImageType,
  title: string,
  text: string,
} & ClientFormProps

function ContactUsPage({ pathname, origin, seoMeta, pageTitle, personalIntro, image, title, text, ...formProps }: ContactUsPage) {
  return (
    <Layout pathname={pathname} origin={origin} headerFix lightGreyBg contentClass="contactUsPage" seoMeta={seoMeta} >
      <ContactUsHero title={pageTitle} intros={personalIntro} image={image} />
      <ContactUsForm title={title} text={text} {...formProps} />
    </Layout>
  )
}

export default ContactUsPage
