import React from "react"
import classNames from "classnames"

import StepItem from "./StepItem"

import { StepsBlockType } from "../../types"

export type StepsProps = {
  title?: string
  stepsBlock: StepsBlockType[]
}

const Steps = ({ title, stepsBlock }: StepsProps) => {
  const renderClassNames = () =>
    classNames({
      "c-howWeWorkSteps": true,
      "c-howWeWorkSteps--withHeadline": !!title,
    })

  const renderSteps = stepsBlock?.map((step, i) => (
    <StepItem
      key={`candidate-step-${i}`}
      order={step.number}
      headline={step.title}
      image={step.image}
      text={step.text}
    />
  ))

  return (
    <section className={renderClassNames()}>
      <div className="container">
        {!!title && (
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 col-xxl-6">
              <h2 className="secondary-heading c-howWeWorkSteps__headline text-center text-lg-left pb-5">
                {title}
              </h2>
            </div>
          </div>
        )}

        {renderSteps}
      </div>
    </section>
  )
}

export default Steps
