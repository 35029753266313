import React from "react"
import classNames from "classnames"
import { renderFilteredRichText } from "../contentEdit"

type DetailDescriptionListProps = {
  title: string
  text: any
  narrowStyle?: boolean
}

const DetailDescriptionList = ({
  title,
  text,
  narrowStyle,
}: DetailDescriptionListProps) => {
  const html = renderFilteredRichText(text)

  const renderClassNames = () =>
    classNames("c-detailDescriptionList", {
      "c-detailDescriptionList--narrow": narrowStyle,
    })

  return html ? (
    <article className={renderClassNames()}>
      <h2 className="c-detailDescriptionList__headline secondary-heading">
        {title}
      </h2>
      <div className="c-detailDescriptionList__content">{html}</div>
    </article>
  ) : null
}

export default DetailDescriptionList
