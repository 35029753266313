import React from "react"

import { SeoMetaType, StoryblokImageType, StepsBlockType } from "../types"
import { WhitepaperFormProps } from "../zohoForms/LandingPageForm"

import Layout from "../components/layout/Layout"
import LandingPageHero from "../components/landingPage/LandingPageHero"
import TextBox from "../components/landingPage/TextBox"
import Diagram from "../components/landingPage/Diagram"
import Steps from "../components/shared/Steps"

type LandingPageProps = {
  pathname: string
  origin: string
  seoMeta: SeoMetaType
  title: string
  text: any
  image: StoryblokImageType
  boxTitle?: string
  boxText?: any
  boxLogo?: StoryblokImageType
  stepsBlock: StepsBlockType[]
  diagramTitle: string
  diagramText: any
  diagramImage: StoryblokImageType
  form: { content: WhitepaperFormProps }
  stepsTitle: string
}

const LandingPage = ({
  pathname,
  origin,
  seoMeta,
  title,
  text,
  image,
  boxTitle,
  boxText,
  boxLogo,
  stepsBlock,
  diagramTitle,
  diagramText,
  diagramImage,
  form,
  stepsTitle,
}: LandingPageProps) => {
  const isTextBox = boxTitle || boxText || boxLogo.filename
  const isDiagram = diagramTitle || diagramText || diagramImage.filename
  const areSteps = stepsBlock?.length > 0

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      seoMeta={seoMeta}
      headerFix
      lightGreyBg
      contentClass="landingPage"
    >
      <LandingPageHero title={title} text={text} image={image} form={form} />
      {isTextBox && <TextBox title={boxTitle} text={boxText} image={boxLogo} />}
      {isDiagram && (
        <Diagram title={diagramTitle} text={diagramText} image={diagramImage} />
      )}
      {areSteps && (
        <Steps title={stepsTitle} withHeadline stepsBlock={stepsBlock} />
      )}
    </Layout>
  )
}

export default LandingPage
