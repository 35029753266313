import React from 'react'
import heroBg from '../../assets/images/team-wall.jpg'
import painting from '../../assets/images/team-painting.png'
import desk from '../../assets/images/team-desk.png'

import { HeaderType } from '../../types'

type MeetTeamHeroProps = {
  content: HeaderType,
}

const MeetTeamHero = ({ content }: MeetTeamHeroProps) => {
  return (
    <section className="c-meetTeamHero">
      <img src={heroBg} alt="iconity" className="c-meetTeamHero__bg" />
      <img src={desk} alt="iconity" className="c-meetTeamHero__flowers" />

      <div className="container">
        <div className="row">
          <div className="col-xl-1 col-xxxl-2" />

          <div className="col-xl-6 col-xxl-5 col-xxxl-4">
            <div className="c-meetTeamHero__content">
              <h1 className="pageHeading c-meetTeamHero__headline">
                {content?.title}
              </h1>
              <div className="c-meetTeamHero__text">
                <p>
                  {content?.text}
                </p>
              </div>
              {content?.ctaButton && (
                <a href={content.ctaTarget.cached_url} className="btnPrimary btn--hoverMove">
                  {content.ctaButton}
                </a>
              )}
            </div>
          </div>

          <div className="col-xl-5 col-xxl-4">
            <img src={painting} className="c-meetTeamHero__painting" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default MeetTeamHero
