import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import PlayIcon from "../../assets/images/icons/youtube-play.svg"

import { StoryblokImageType, BlogAuthorTypeContent } from "../../types"

type OnDemandWebinarHeroProps = {
  openYoutubeOverlay: () => void
  title: string
  youtubeImage: StoryblokImageType
  watchTime: string
  presenters: BlogAuthorTypeContent[]
}

const OnDemandWebinarHero = ({
  openYoutubeOverlay,
  title,
  youtubeImage,
  watchTime,
  presenters,
}: OnDemandWebinarHeroProps) => {
  const webinarPresenters = presenters.map((presenter, i) => (
    <div
      key={`on-demand-webinar-${i}`}
      className="c-onDemandWebinarHero__presenter d-flex align-items-center"
    >
      <StoryblokImage
        image={presenter?.content?.profilePhoto}
        className="c-onDemandWebinarHero__presenter-photo"
      />
      <span className="c-onDemandWebinarHero__presenter-name">
        {presenter?.content?.name}
      </span>
    </div>
  ))

  return (
    <section className="c-onDemandWebinarHero">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-xxl-5">
            <div className="c-onDemandWebinarHero__text">
              <h1 className="c-onDemandWebinarHero__title">{title}</h1>
              <p className="c-onDemandWebinarHero__watchTime">{watchTime}</p>
              <div className="d-flex flex-wrap">{webinarPresenters}</div>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-7">
            <div
              className="c-onDemandWebinarHero__wrapper"
              onClick={() => openYoutubeOverlay()}
            >
              <StoryblokImage
                image={youtubeImage}
                className="c-onDemandWebinarHero__image"
              />
              <PlayIcon className="c-onDemandWebinarHero__play" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnDemandWebinarHero
