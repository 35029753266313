import React from 'react'

import ClientForm from '../../zohoForms/ClientForm'

import { ClientFormProps } from '../../zohoForms/ClientForm'

type ContactUsFormProps = {
  title: string,
  text: string,
} & ClientFormProps

const ContactUsForm = ({ title, text, ...formProps }: ContactUsFormProps) => {
  return (
    <section className="c-contactUsForm">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-7 col-xl-5 col-xxl-4">
            <div className="c-contactUsForm__wrapper text-center">
              <h2 className="c-contactUsForm__headline secondary-heading">
                {title}
              </h2>
              <p className="c-contactUsForm__text">
                {text}
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-7 col-xl-5 col-xxl-4">
            <div className="c-contactUsForm__form">
              <ClientForm {...formProps} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactUsForm
