import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { HeaderWithImageType } from "../../types"

type FindSpecialistHeroProps = {
  header: HeaderWithImageType,
}

const FindSpecialistHero = ({ header }: FindSpecialistHeroProps) => {

  return (
    <div className="c-findSpecialistHero">

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-9 offset-md-2 col-lg-8 col-xl-5 offset-xl-1 col-xxl-5 col-xxxl-4">
            <div className="c-findSpecialistHero__content">
              <h1 className="c-findSpecialistHero__headline">
                {header.title}
              </h1>
              <p className="c-findSpecialistHero__text">
                {header.text}
              </p>
              <a href={header.ctaTarget.cached_url} className="btnPrimary btn--hoverMove">
                {header.ctaButton}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container c-findSpecialistHero__bgContainer">
        <div className="row">
          <div className="col-12 offset-md-2 col-10">
            <StoryblokImage image={header.image} className="c-findSpecialistHero__bg"/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindSpecialistHero
