import React, { useState } from "react"
import { HeaderContentType } from '../../../types'
import LangSelector from "./LangSelector"
import ArrowIcon from "../../../assets/images/icons/arrow-right-big.svg"
import classNames from "classnames"
import DesktopNavDropdown from "./DesktopNavDropdown"

type DesktopMenuProps = {
  content: HeaderContentType | undefined,
  pathname: string,
  activeLanguage: string,
  isThankYou: boolean,
}

const DesktopMenu = ({ content, activeLanguage, isThankYou, pathname }: DesktopMenuProps) => {
  const [expandedMenuItem, setExpandedMenuItem] = useState<number>();

  const getMenuItemClassNames = (i: number, groupLink: string) =>
    classNames("topNav__menuItem", {
      "topNav__menuItem--active": expandedMenuItem === i,
      "topNav__menuItem--activeLink": pathname.includes(groupLink),
    });

  const closeMenu = (e: Event) => {
    if (!(e.target as HTMLElement).classList.contains("topNav__menuItemLabel")) {
      setExpandedMenuItem(null)
    }
  };

  const menuLinks = content?.menuItems.map((item, i) => {
    const hasSubitems = item.submenuItems.length > 0;
    return (
      <li key={`desktop-menu-${i}`} className={getMenuItemClassNames(i, item.link.cached_url)}>

        {hasSubitems && <>
          <span
            className="topNav__menuItemLabel hoverUnderlineAnimation"
            onClick={() => setExpandedMenuItem(expandedMenuItem === i ? null : i)}
          >
            {item.label}
            <ArrowIcon/>
          </span>
          <DesktopNavDropdown
            isExpanded={expandedMenuItem === i}
            closeDropdown={closeMenu}
            items={item.submenuItems}
            pathname={pathname}
          />
        </>}

        {!hasSubitems && <a
          href={item.link?.cached_url}
          className="topNav__menuItemLabel hoverUnderlineAnimation"
        >{item.label}</a>}

      </li>
    );
  });

  return (
    <div className="d-none d-xl-flex">
      <nav>
        <ul className="topNav d-none d-xl-flex align-items-center">
          {menuLinks}
          {content?.secondaryButtonLabel && content?.secondaryButtonLink.cached_url && (
            <li className="topNav__menuItem">
              <a href={content?.secondaryButtonLink.cached_url} className="btnSecondary">
                {content?.secondaryButtonLabel}
              </a>
            </li>
          )}
          {content?.buttonLabel && content?.buttonLink.cached_url && (
            <li className="topNav__menuItem ml-4">
              <a href={content?.buttonLink.cached_url} className="btnPrimary">
                {content?.buttonLabel}
              </a>
            </li>
          )}
        </ul>
      </nav>
      <LangSelector pathname={pathname} activeLanguage={activeLanguage} isThankYou={isThankYou} />
    </div>
  )
}

export default DesktopMenu
