import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

type WebinarContentProps = {
  contentHeader: string;
  content: string;
}

const WebinarContent = ({ content, contentHeader }: WebinarContentProps) => {

  return (
    <div className="c-webinarContent">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 col-iconity-36">
            <h3 className="c-webinarContent__headline">{contentHeader}</h3>
            <div className="c-webinarContent__text c-richText">{render(content)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebinarContent
