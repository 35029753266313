import React from "react"
import ArrowIcon from "../../assets/images/icons/arrow-right-2.svg"
import { CandidateListingType } from "../../types"
import LocationIcon from "../../assets/images/icons/location.svg"
import FileIcon from "../../assets/images/icons/file.svg"

type CandidateListingProps = {
  title: string
  candidateListing: CandidateListingType[]
  onClick?: (candidate: CandidateListingType) => void
}

const CandidateListing = ({
  candidateListing,
  title,
  onClick,
}: CandidateListingProps) => {
  const items = candidateListing?.map((candidate, i) => {
    return (
      <div
        className="c-candidateList__item"
        key={i}
        onClick={() => onClick && onClick(candidate)}
      >
        <div className="c-candidateList__item-jobTitle">
          {candidate.jobTitle}
        </div>
        <div className="c-candidateList__item-location">
          <LocationIcon />
          {candidate.location}
        </div>
        <div className="c-candidateList__item-contractType">
          <FileIcon />
          {candidate.contractType}
        </div>
        <div className="c-candidateList__item-icon">
          <ArrowIcon />
        </div>
      </div>
    )
  })

  return (
    <div className="c-candidateList">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10 col-xl-9 col-xxxl-8">
            <div className="c-candidateList__content d-flex flex-column align-items-start align-items-sm-center">
              <div className="c-candidateList__title">{title}</div>
              {items}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateListing
