import React from "react"
import Layout from "../components/layout/Layout"
import { SeoMetaType, WebinarType } from "../types"
import WebinarHero from "../components/webinar/WebinarHero"
import WebinarContent from "../components/webinar/WebinarContent"
import StickyForm from "../components/webinar/StickyForm"
import WebinarMobileForm from "../components/webinar/WebinarMobileForm"

type WebinarDetailProps = {
  seoMeta: SeoMetaType
  pathname: string
  origin: string
} & WebinarType

const WebinarDetail = ({
  seoMeta,
  pathname,
  origin,
  content,
  contentHeader,
  reusableForm,
  date,
  presenters,
  webinarTitle,
  thankYouPage,
  headerImageThumb,
}: WebinarDetailProps) => {
  return (
    <Layout
      pathname={pathname}
      origin={origin}
      contentClass="webinarPage"
      seoMeta={seoMeta}
      sticky
    >
      <StickyForm
        formSource={webinarTitle}
        thankYouPage={thankYouPage}
        reusableForm={reusableForm.content}
      />
      <div className="webinarPage__sectionWrapper">
        <WebinarHero
          date={date}
          presenters={presenters}
          webinarTitle={webinarTitle}
          image={headerImageThumb}
        />
        <WebinarContent content={content} contentHeader={contentHeader} />
        <WebinarMobileForm
          formSource={webinarTitle}
          thankYouPage={thankYouPage}
          reusableForm={reusableForm.content}
        />
      </div>
    </Layout>
  )
}

export default WebinarDetail
