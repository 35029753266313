import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import CheckBox from "./formComponents/CheckBox"
import { CommonFormProps } from "../types"
import SubmitButton from "./formComponents/SubmitButton"

const NEWSLETTER_CLIENTS_SUBMIT_URL =
  "https://form.iconity.ai/simplitygroup/form/IconityNewsletterSignupClients/formperma/zwk1N8-I4EnsuIV7sQnquTg3_3hs3scrVm4RtYbMUD0/htmlRecords/submit"
const NEWSLETTER_CANDIDATES_SUBMIT_URL =
  "https://form.iconity.ai/simplitygroup/form/IconityNewsletterSignupCandidates1/formperma/5jqRBn3DwXewhWBWQKlY29GfhipWuuhJPFrmM_ld9mg/htmlRecords/submit"
const CLIENTS_FORM_ID = "Newsletter Signup Form Clients"
const CANDIDATES_FORM_ID = "Newsletter Signup Form Candidates"

type WebinarFormFields = {
  firstName: string
  lastName: string
  email: string
  company?: string
  linkedIn?: string
  marketing: boolean
}

const commonZohoClientFormFields: ZohoFormFields<WebinarFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  marketing: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
}

const clientsZohoClientFormFields: ZohoFormFields<WebinarFormFields> = {
  ...commonZohoClientFormFields,
  company: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: true,
  },
}

const candidatesZohoClientFormFields: ZohoFormFields<WebinarFormFields> = {
  ...commonZohoClientFormFields,
  linkedIn: {
    zohoFieldName: "SingleLine9",
    required: false,
    initialValue: "",
  },
}

export type NewsletterSignupFormProps = {
  companyLabel: string
  linkedInLabel: string
} & CommonFormProps<WebinarFormFields>

const NewsletterSignupForm = ({
  titleForm,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  hideNewsletter,
  ...labels
}: NewsletterSignupFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<WebinarFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl:
      formSource === CLIENTS_FORM_ID
        ? NEWSLETTER_CLIENTS_SUBMIT_URL
        : NEWSLETTER_CANDIDATES_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields:
      formSource === CLIENTS_FORM_ID
        ? clientsZohoClientFormFields
        : candidatesZohoClientFormFields,
  })

  return (
    <div className="form__container">
      {titleForm && <h3 className="form__headline">{titleForm}</h3>}
      <div className="form__row">
        <TextField
          name="firstName"
          label={labels.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={labels.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <TextField
        name="email"
        label={labels.emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      {formSource === CLIENTS_FORM_ID && (
        <TextField
          name="company"
          label={labels.companyLabel}
          value={values.company}
          setValue={setValue}
          error={errors.company}
          clearError={clearError}
        />
      )}
      {formSource === CANDIDATES_FORM_ID && (
        <TextField
          name="linkedIn"
          label={labels.linkedInLabel}
          value={values.linkedIn}
          setValue={setValue}
          error={errors.linkedIn}
          clearError={clearError}
        />
      )}
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={labels.marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <SubmitButton
        isFetching={isFetching}
        doSubmit={doSubmit}
        submitLabel={submitLabel}
        submitError={submitError}
      />
    </div>
  )
}

export default NewsletterSignupForm
