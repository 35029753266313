import React from 'react'
import StoryblokImage from '../shared/StoryblokImage'
import { HeaderWithImageType } from '../../types'

type ProcessHeroProps = {
  header: HeaderWithImageType,
}

const ProcessHero = ({ header }: ProcessHeroProps) => {
  return (
    <section className="c-processHero">
      <StoryblokImage image={header.image} className="c-processHero__bg" />

      <div className="container">
        <div className="row">
          <div className="col-xl-0 col-xxl-1" />

          <div className="col-12 col-md-7 col-lg-8 col-xl-6 col-xxl-5 col-xxxl-4">
            <div className="c-processHero__content">
              <h1 className="c-processHero__headline">
                {header?.title}
              </h1>
              <p className="c-processHero__text">
                {header?.text}
              </p>
              {header?.ctaButton && header?.ctaTarget.cached_url && (
                <a href={header.ctaTarget.cached_url} className="btnPrimary btn--hoverMove">
                  {header.ctaButton}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProcessHero
