import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { StoryblokImageType } from "../../types"
import { cutInLines } from "../contentEdit"

type ResourcesCentreHeroProps = {
  title: string;
  text: string;
  image: StoryblokImageType;
}

const ResourcesCentreHero = ({ image, text, title }: ResourcesCentreHeroProps) => {

  return (
    <div className="c-resourcesCentreHero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-xl-4 offset-xxl-1 c-resourcesCentreHero__content col-iconity-36">
            <h1 className="c-resourcesCentreHero__headline">
              {cutInLines(title)}
            </h1>
            <div className="c-resourcesCentreHero__leadText">
              {text}
            </div>
          </div>
          <StoryblokImage image={image} className="c-resourcesCentreHero__image col-12 d-md-none col-iconity-36"/>
        </div>
      </div>
      <StoryblokImage image={image} className="c-resourcesCentreHero__image d-none d-md-block"/>
    </div>
  )
}

export default ResourcesCentreHero
