import React from 'react'

import FacebookLogo from '../../assets/images/icons/facebook.svg'
import LinkedinLogo from '../../assets/images/icons/linkedin.svg'
import InstagramLogo from '../../assets/images/icons/instagram.svg'

import { ctaTarget } from '../../types'

type FooterSocialsProps = {
  facebook: ctaTarget,
  linkedin: ctaTarget,
  instagram: ctaTarget,
}

const FooterSocials = ({ facebook, linkedin, instagram }: FooterSocialsProps) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="footer__socials d-flex justify-content-xl-end align-items-center">
          {facebook?.cached_url && (
            <a href={facebook.cached_url} target="_blank" rel="noopener noreferrer" className="footer__socials-item">
              <FacebookLogo />
            </a>
          )}
          {linkedin?.cached_url && (
            <a href={linkedin.cached_url} target="_blank" rel="noopener noreferrer" className="footer__socials-item">
              <LinkedinLogo />
            </a>
          )}
          {instagram?.cached_url && (
            <a href={instagram.cached_url} target="_blank" rel="noopener noreferrer" className="footer__socials-item">
              <InstagramLogo />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default FooterSocials
