import React, { useRef, useEffect } from "react"
import Slider from "react-slick"
import classNames from "classnames"

import { TestimonialType } from "../../types"

import NextArrow from "../../assets/images/icons/arrow-next.svg"
import PrevArrow from "../../assets/images/icons/arrow-prev.svg"

import StoryblokImage from "./StoryblokImage"

type TestimonialSliderProps = {
  activeSlide: number
  showNextSlide: () => void
  showPrevSlide: () => void
  showSlide: (slideId: number) => void
  testimonials: Array<TestimonialType>
}

const TestimonialSlider = ({
  activeSlide,
  showNextSlide,
  showPrevSlide,
  showSlide,
  testimonials,
}: TestimonialSliderProps) => {
  const slider = useRef(null)

  useEffect(() => {
    slider.current.slickGoTo(activeSlide)
  }, [activeSlide])

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    draggable: false,
  }

  const renderDotClassNames = dotIndex => {
    return classNames({
      "c-testimonialSlider__singleDot": true,
      "c-testimonialSlider__singleDot--active": activeSlide === dotIndex,
    })
  }

  const slideControls = testimonials?.map((slide, i) => (
    <span
      key={`slide-control-${i}`}
      className={renderDotClassNames(i)}
      onClick={() => showSlide(i)}
    />
  ))

  const slides = testimonials?.map((slide, i) => (
    <div key={`slide-${i}`} className="c-testimonialSlider__item d-flex">
      <div className="c-testimonialSlider__text">
        <div className="c-testimonialSlider__description order-1">
          <p>{slide.content?.text}</p>
        </div>
        <div className="c-testimonialSlider__who order-3 order-md-2">
          <p className="c-testimonialSlider__name">{slide.content?.name}</p>
          <p className="c-testimonialSlider__position">
            {slide.content?.position}
          </p>
          {slide.content && (
            <StoryblokImage image={slide.content.companyLogo} />
          )}
          <div className="d-flex d-md-none align-items-center mt-4">
            <button
              className="c-testimonialSlider__singleButton d-flex align-items-center"
              onClick={() => showPrevSlide()}
            >
              <PrevArrow />
            </button>

            <button
              className="c-testimonialSlider__singleButton d-flex align-items-center"
              onClick={() => showNextSlide()}
            >
              <NextArrow />
            </button>
          </div>
        </div>
        <div className="c-testimonialSlider__mobileImages d-lg-none order-2 order-md-3">
          {slide.content && (
            <StoryblokImage
              image={slide.content.image}
              className="c-testimonialSlider__mobileImage"
            />
          )}
          <div className="d-flex justify-content-center justify-content-md-between align-items-center mt-3 pt-2">
            <div className="c-testimonialSlider__dots d-flex">
              {slideControls}
            </div>

            <div className="c-testimonialSlider__buttons d-none d-md-flex align-items-center">
              <button
                className="c-testimonialSlider__singleButton d-flex align-items-center"
                onClick={() => showPrevSlide()}
              >
                <PrevArrow />
              </button>

              <button
                className="c-testimonialSlider__singleButton d-flex align-items-center"
                onClick={() => showNextSlide()}
              >
                <NextArrow />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="c-testimonialSlider__controls">
        {slide.content && (
          <StoryblokImage
            image={slide.content.image}
            className="c-testimonialSlider__image"
          />
        )}
        <div className="d-flex justify-content-between align-items-center">
          <div className="c-testimonialSlider__dots d-flex">
            {slideControls}
          </div>

          <div className="c-testimonialSlider__buttons d-flex align-items-center">
            <button
              className="c-testimonialSlider__singleButton d-flex align-items-center"
              onClick={() => showPrevSlide()}
            >
              <PrevArrow />
            </button>

            <button
              className="c-testimonialSlider__singleButton d-flex align-items-center"
              onClick={() => showNextSlide()}
            >
              <NextArrow />
            </button>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div className="c-testimonialSlider">
      <Slider ref={slider} {...settings}>
        {slides}
      </Slider>
    </div>
  )
}

export default TestimonialSlider
