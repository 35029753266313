import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import { HeaderType } from "../../types"

import HeroTop from "./HeroTop"
import HeroCareer from "./HeroCareer"
import HeroTalents from "./HeroTalents"

export type HeroProps = {
  headline: string
  subHeadline: string
  header: Array<HeaderType>
}

const Hero = ({ headline, subHeadline, header }: HeroProps) => {
  return (
    <section className="c-hero">
      <div className="container h-100 d-flex flex-column">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center">
            <HeroTop headline={headline} subHeadline={subHeadline} />
          </div>
        </div>

        <div className="row flex-fill">
          <div className="col-md-6 order-2 order-md-1">
            <HeroCareer content={header[0]} />
          </div>

          <div className="col-md-6 order-1 order-md-2">
            <HeroTalents content={header[1]} />
          </div>
        </div>
      </div>

      <button
        className="c-hero__scrollButton"
        onClick={() => scrollTo("#scrollgoal")}
      >
        <span className="c-hero__scrollButton-content">
          <span className="c-hero__scrollButton-arrow"></span>
        </span>
      </button>
    </section>
  )
}

export default Hero
