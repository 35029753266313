import React from "react"
import Layout from "../components/layout/Layout"
import { BlogPostType, SeoMetaType } from "../types"
import BlogPostHero from "../components/blog/BlogPostHero"
import BlogPostText from "../components/blog/BlogPostText"
import BlogPostAuthorBio from "../components/blog/BlogPostAuthorBio"
import Crossroads from "../components/shared/Crossroads"

type BlogPostProps = {
  seoMeta: SeoMetaType
  pathname: string
  origin: string
} & BlogPostType;

const BlogPost = ({
                    seoMeta,
                    pathname,
                    blogBody,
                    blogTitle,
                    reusableCtaBanner,
                    datePublished,
                    headerImage,
                    headerImageThumb,
                    leadParagraph,
                    readTime,
                    tag,
                    origin,
                    authors
                  }: BlogPostProps) => {
  const blogAuthors = (authors || []).map(q => q.content).filter(q => !!q)
  return (
    <Layout pathname={pathname} origin={origin} contentClass="blogPostPage" seoMeta={seoMeta}>
      <BlogPostHero
        title={blogTitle}
        tag={tag}
        authors={blogAuthors}
        headerImage={headerImage}
        readTime={readTime}
        datePublished={datePublished}
      />

      <BlogPostText
        leadParagraph={leadParagraph}
        blogBody={blogBody}
      />

      <BlogPostAuthorBio
        authors={blogAuthors}
      />

      {reusableCtaBanner && reusableCtaBanner.content && <Crossroads
        ctaBanner={reusableCtaBanner.content}
      />}
    </Layout>
  )
}

export default BlogPost
