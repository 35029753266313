import React from "react"
import Layout from "../components/layout/Layout"
import { SeoMetaType } from "../types"
import { renderFilteredRichText } from "../components/contentEdit"

type RichTextPageProps = {
  seoMeta: SeoMetaType
  pathname: string
  origin: string
  text: any
};

const BlogPost = ({
                    seoMeta,
                    pathname,
                    origin,
                    text
                  }: RichTextPageProps) => {
  return (
    <Layout pathname={pathname} origin={origin} contentClass="richTextPage" seoMeta={seoMeta}>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-7 c-richText">
              {renderFilteredRichText(text)}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost
