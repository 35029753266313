import React from "react"
import classNames from "classnames"
import { ThreeElementBlockType } from "../../types"
import ThreeElementBlock from "./ThreeElementBlock"
import { cutInLines } from "../contentEdit"

export type ThreeElementBlockContainerType = {
  title?: string
  blocks: ThreeElementBlockType[]
  className?: string
}

const ThreeElementBlockContainer = ({
  title,
  blocks,
  className,
}: ThreeElementBlockContainerType) => {
  const items = blocks?.map((block, i) => (
    <ThreeElementBlock {...block} key={i} />
  ))

  return (
    <div className={classNames("c-threeBlockContainer", className)}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-10 d-flex flex-column align-items-center">
            <h2 className="c-threeBlockContainer__title">
              {cutInLines(title)}
            </h2>
            <div className="c-threeBlockContainer__blocks row">{items}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreeElementBlockContainer
