import React, { useState } from "react"
import classNames from "classnames"
import Navbar from 'react-bootstrap/Navbar'
import { HeaderContentType } from '../../../types'
import LangSelector from "./LangSelector"
import Submenu from "./MobileSubMenu"

type MobileMenuProps = {
  isMenuVisible: boolean,
  content: HeaderContentType | undefined,
  pathname: string,
  activeLanguage: string,
  isThankYou: boolean,
}

const MobileMenu = ({ isMenuVisible, content, pathname, activeLanguage, isThankYou }: MobileMenuProps) => {
  const [expandedMenuItem, setExpandedMenuItem] = useState<number>()
  const menuItems = content?.menuItems.map((item, i) => {
    const groupSelected = pathname.includes(item?.link.cached_url);
    const hasSubitems = item.submenuItems.length > 0;
    const activeLinkClassname = groupSelected ? 'topNav__menuItem--activeLink' : '';

    return (
      <div className={classNames('topNav__mobileMenuItem d-flex flex-column align-items-center', activeLinkClassname)} key={`mobile-menu-${i}`}>
        {hasSubitems
          ? <Submenu
            items={item.submenuItems}
            label={item.label}
            onMenuItemOpen={() => setExpandedMenuItem(expandedMenuItem === i ? undefined : i)}
            isExpanded={expandedMenuItem === i}
          />
          : <a href={item.link?.cached_url} className={classNames('topNav__menuItemLabel', activeLinkClassname)}>{item.label}</a>
        }
      </div>
    )
  })

  return (
    <Navbar expanded={isMenuVisible} expand="xl" className="d-xl-none pt-0 pb-0">
      <Navbar.Collapse>
        <div className="topNav__mobileMenu d-flex flex-column">
          {menuItems}
          {content?.secondaryButtonLabel && content?.secondaryButtonLink.cached_url && (
            <div className="topNav__mobileMenuItem topNav__mobileMenuItem--button d-flex flex-column align-items-center">
              <a href={content?.secondaryButtonLink.cached_url} className="btnSecondary">
                {content?.secondaryButtonLabel}
              </a>
            </div>
          )}
          {content?.buttonLabel && content?.buttonLink.cached_url && (
            <div className="topNav__mobileMenuItem topNav__mobileMenuItem--button d-flex flex-column align-items-center">
              <a href={content?.buttonLink.cached_url} className="btnPrimary">
                {content?.buttonLabel}
              </a>
            </div>
          )}
          <LangSelector pathname={pathname} activeLanguage={activeLanguage} isThankYou={isThankYou} />
        </div>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MobileMenu
