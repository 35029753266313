import React from "react"

import DetailDescriptionArticle from "./DetailDescriptionArticle"
import DetailDescriptionList from "./DetailDescriptionList"

type DetailDescriptionProps = {
  section1Title: string
  section1Text: any
  section2Title: string
  section2Text: any
  section3Title: string
  section3Text: any
  section4Title: string
  section4Text: any
}

const DetailDescription = ({
  section1Title,
  section1Text,
  section2Title,
  section2Text,
  section3Title,
  section3Text,
  section4Title,
  section4Text,
}: DetailDescriptionProps) => {
  return (
    <div className="c-detailDescription">
      <DetailDescriptionList
        title={section1Title}
        text={section1Text}
        narrowStyle
      />
      <DetailDescriptionList title={section2Title} text={section2Text} />
      <DetailDescriptionList title={section3Title} text={section3Text} />
      <DetailDescriptionList title={section4Title} text={section4Text} />
    </div>
  )
}

export default DetailDescription
