import React from "react"
import { StoryblokImageType } from "../../types"
import { cutInLines } from "../contentEdit"
import StoryblokImage from "./StoryblokImage"

export type LogosListProps = {
  title?: string
  logos: StoryblokImageType[]
}

const LogosList = ({ title, logos }: LogosListProps) => {
  const items = logos?.map((logo, i) => (
    <div className="c-logosList__logo" key={`logo-${i}`}>
      <StoryblokImage image={logo} />
    </div>
  ))

  return (
    <div className="c-logosList">
      <div className="container">
        <div className="row justify-content-center">
          <h2 className="c-logosList__title col-12">{cutInLines(title)}</h2>
          <div className="c-logosList__logos col-12 col-lg-10">{items}</div>
        </div>
      </div>
    </div>
  )
}

export default LogosList
