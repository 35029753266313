import React, { SyntheticEvent, useState } from "react"

export type FileUploadProps = {
  name: string;
  label?: string;
  setValue: (name: string, value: File) => void;
  error?: string;
  clearError?: (name: string) => void;
  ctaLabel: string,
}

const FileUpload = ({ name, label, setValue, error, clearError, ctaLabel }: FileUploadProps) => {
  const [fileName, setFileName] = useState(undefined)
  const hasError = !!error;

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (error && clearError) {
      clearError(name);
    }
    setValue(name, e.currentTarget.files[0]);
    setFileName(e.currentTarget.files[0]?.name)
  };

  return (
    <div className={`fileUpload ${hasError ? 'fileUpload--error' : ''}`}>
      <span className={'fileUpload__label'}>
        {label}
      </span>
      <label htmlFor={name} className="btnSecondary btnSecondary--smallHeight">
        {ctaLabel}
      </label>
      <input
        id={name}
        name={name}
        type="file"
        onChange={handleChange}
        className="fileUpload__input"
      />
      <p className="fileUpload__file">
        {fileName ? fileName : ''}
      </p>
      {hasError ?
        <span className={'fileUpload__error'}>
          {error}
        </span>
        : null
      }
    </div>
  );
}

export default FileUpload;
