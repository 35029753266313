import React, { useRef, useEffect } from 'react'
import classNames from 'classnames'
import RichTextResolver from 'storyblok-js-client/dist/rich-text-resolver.es'
import StoryblokImage from '../shared/StoryblokImage'

const resolver = new RichTextResolver()

type FindJobOverlay = {
  close: () => void,
  content: any,
}

const FindJobOverlay = ({ close, content }: FindJobOverlay) => {
  const node = useRef(null)

  const html = content[0]?.textLeft ? resolver.render(content[0].textLeft) : ''

  useEffect(() => {
    if (content.length) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [content]);

  const handleClickOutside = e => {
    if (!node.current.contains(e.target)) {
      close()
    }
  };

  const renderClassNames = () => (
    classNames({
      'c-findJobSelection__overlay': true,
      'c-findJobSelection__overlay--shown': Boolean(content.length),
    })
  )

  return (
    <div className={renderClassNames()} ref={node}>
      <div className="c-findJobSelection__overlay-close" onClick={() => close()}/>
      <div className="c-findJobSelection__overlay-top d-flex align-items-center">
        <StoryblokImage image={content[0]?.iconHover} className="c-findJobSelection__overlay-icon" />
        {content[0]?.title}
      </div>
      <div dangerouslySetInnerHTML={{ __html: html }} className="c-findJobSelection__overlay-content" />
      <a href={content[0]?.ctaLink.cached_url} className="c-findJobSelection__overlay-link btnPrimary btnPrimary--fullWidth">
        {content[0]?.ctaButton}
      </a>
    </div>
  )
}

export default FindJobOverlay
