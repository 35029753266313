import React from "react"
import { cutInLines } from "../contentEdit"
import { FiftyFiftyBlockType } from "../../types"
import StoryblokImage from "../shared/StoryblokImage"

const FiftyFiftyBlock = ({ title, text, image, ctaButton, ctaTarget }: FiftyFiftyBlockType) => {
  return (
    <section className="c-fiftyFiftyBlock">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 col-xl-5 offset-lg-1">
            <div className="c-fiftyFiftyBlock__content">
              <h2 className="c-fiftyFiftyBlock__headline">{cutInLines(title)}</h2>
              <p className="c-fiftyFiftyBlock__text">{text}</p>
              {ctaButton && <a href={ctaTarget.cached_url} className="btnPrimary">
                {ctaButton}
              </a>}
            </div>
          </div>
        </div>
      </div>

      <div className="c-fiftyFiftyBlock__imageWrapper container">
        <StoryblokImage className="c-fiftyFiftyBlock__image" image={image}/>
      </div>
    </section>
  )
}

export default FiftyFiftyBlock
