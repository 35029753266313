import React from "react"
import { companySocial, SeoMetaType } from "../types"

import Layout from '../components/layout/Layout'
import GetInTouchContent from '../components/getInTouch/GetInTouch'
import { GetInTouchFormProps } from "../zohoForms/GetInTouchForm"

export type GetInTouchProps = {
  title: string,
  text: string,
  companyEmail: string,
  companyPhone: string,
  companyName: string,
  companyInfo: any,
  companyFacebook: companySocial,
  companyLinkedin: companySocial,
  companyInstagram: companySocial,
  pathname: string,
  origin: string,
  seoMeta: SeoMetaType,
} & GetInTouchFormProps;

function GetInTouch({
  title,
  text,
  companyEmail,
  companyPhone,
  companyName,
  companyInfo,
  companyFacebook,
  companyLinkedin,
  companyInstagram,
  pathname,
  origin,
  seoMeta,
  ...formProps
}: GetInTouchProps) {
  return (
    <Layout pathname={pathname} origin={origin} seoMeta={seoMeta} headerFix contentClass="getInTouchPage">
      <GetInTouchContent
        headline={title}
        subHeadline={text}
        companyEmail={companyEmail}
        companyPhone={companyPhone}
        companyName={companyName}
        companyInfo={companyInfo}
        companyFacebook={companyFacebook}
        companyLinkedin={companyLinkedin}
        companyInstagram={companyInstagram}
        {...formProps}
      />
    </Layout>
  )
}

export default GetInTouch
