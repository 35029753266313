import React from "react"
import classNames from "classnames"

import StoryblokLink from "../shared/StoryblokLink"

import HotIcon from "../../assets/images/icons/hot.svg"
import LocationIcon from "../../assets/images/icons/location.svg"
import FileIcon from "../../assets/images/icons/file.svg"
import MoneyIcon from "../../assets/images/icons/money.svg"

import { checkLinkUrl } from "../contentEdit"

import { JobDescriptionNodeType } from "../../types"

export type PositionItemProps = {
  hot?: boolean
  job: JobDescriptionNodeType
}

const PositionItem = ({ hot, job }: PositionItemProps) => {
  const renderItemClassNames = () =>
    classNames({
      "c-positionItem": true,
      "c-positionItem--hot": hot,
    })

  return (
    <a href={checkLinkUrl(job?.full_slug)} className={renderItemClassNames()}>
      {hot && <HotIcon className="c-positionItem__icon" />}
      <div className="c-positionItem__cell c-positionItem__name">
        {job?.content.title}
      </div>
      <div className="c-positionItem__cell c-positionItem__location">
        <div className="c-positionItem__location-icon">
          <LocationIcon />
        </div>
        {job?.content.location}
      </div>
      <div className="c-positionItem__cell c-positionItem__type">
        <div className="c-positionItem__location-icon">
          <FileIcon />
        </div>
        {job?.content.contractType}
      </div>
      <div className="c-positionItem__cell c-positionItem__salary">
        <div className="c-positionItem__location-icon">
          <MoneyIcon />
        </div>
        {job?.content.salary}
      </div>
    </a>
  )
}

export default PositionItem
