import "../assets/styles/start.scss"

import ComponentNotFound from "./component_not_found"

import HomePage from "../iconityPages/Home"
import GetInTouch from "../iconityPages/GetInTouch"
import FindJob from "../iconityPages/FindJob"
import Page404 from "../iconityPages/Page404"
import ThankYou from "../iconityPages/ThankYou"
import WhyAreWeDifferent from "../iconityPages/WhyWeAreDifferent"
import OpenPositions from "../iconityPages/OpenPositions"
import OurProcess from "../iconityPages/OurProcess"
import FindSpecialist from "../iconityPages/FindSpecialist"
import PositionDetail from "../iconityPages/PositionDetail"
import ContactUs from "../iconityPages/ContactUs"
import MeetTeam from "../iconityPages/MeetTeam"
import Header from "../components/layout/Header"
import BlogPost from "../iconityPages/BlogPost"
import WhitepaperDetail from "../iconityPages/WhitepaperDetail"
import WebinarDetail from "../iconityPages/WebinarDetail"
import ResourcesCentre from "../iconityPages/ResourcesCentre"
import RichTextPage from "../iconityPages/RichTextPage"
import BlogListing from "../iconityPages/BlogListing"
import NewsletterSignupPage from "../iconityPages/NewsletterSignupPage"
import CompanyJobsPage from "../iconityPages/CompanyJobs"
import OnDemandWebinar from "../iconityPages/OnDemandWebinar"
import LandingPage from "../iconityPages/LandingPage"

/* eslint-disable */
const ComponentList = {
  // 'Unique Pages'
  ["Home"]: HomePage,
  ["GetInTouch"]: GetInTouch,
  ["FindJob"]: FindJob,
  ["error404"]: Page404,
  ["thankYou"]: ThankYou,
  ["whyWeAreDifferent"]: WhyAreWeDifferent,
  ["openPositions"]: OpenPositions,
  ["ourProcess"]: OurProcess,
  ["findSpecialist"]: FindSpecialist,
  ["findSpecialistNew"]: FindSpecialist,
  ["JobDescription"]: PositionDetail,
  ["contactUs"]: ContactUs,
  ["meetTheTeam"]: MeetTeam,
  ["topNavigation"]: Header,
  ["Blog"]: BlogPost,
  ["Blog Listing"]: BlogListing,
  ["Whitepaper"]: WhitepaperDetail,
  ["Webinar"]: WebinarDetail,
  ["Resources Centre"]: ResourcesCentre,
  ["Rich Text Page"]: RichTextPage,
  ["Newsletter Signup Page"]: NewsletterSignupPage,
  ["companyJobs"]: CompanyJobsPage,
  ["onDemandWebinar"]: OnDemandWebinar,
  ["landingPage"]: LandingPage,
}
/* eslint-enable */

const Components = type => {
  /*if (NavigationComponents(type)) {
    return NavigationComponents(type);
  }*/

  if (typeof ComponentList[type] === "undefined") {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
