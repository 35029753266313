import React from "react"
import { PageProps } from 'gatsby'

import Layout from '../components/layout/Layout'
import PaintingHero from '../components/shared/PaintingHero'
import Steps from '../components/shared/Steps'
import Testimonials from '../components/shared/Testimonials'
import Crossroads from '../components/shared/Crossroads'

import { HeaderType, SeoMetaType, StepsBlockType, TestimonialType, CtaBanner } from '../types'

type HowWeWorkPageProps = {
  pathname: string,
  origin: string,
  header: Array<HeaderType>,
  seoMeta: SeoMetaType,
  stepsBlock: Array<StepsBlockType>,
  title: string,
  text: string,
  expertiseGuarantorTestimonial: Array<TestimonialType>,
  ctaBanner: Array<CtaBanner>,
}

function HowWeWorkPage({ pathname, origin, header, seoMeta, stepsBlock, title, text, expertiseGuarantorTestimonial, ctaBanner }: HowWeWorkPageProps) {
  return (
    <Layout pathname={pathname} origin={origin} headerFix lightGreyBg seoMeta={seoMeta} >
      <PaintingHero content={header[0]} />
      <Steps withHeadline stepsBlock={stepsBlock} title={title} />
      <Testimonials testimonials={expertiseGuarantorTestimonial} isTestimonial={false} headline={text} />
      <Crossroads ctaBanner={ctaBanner[0]} />
    </Layout>
  )
}

export default HowWeWorkPage
