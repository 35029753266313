import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { BlogAuthorType, StoryblokImageType } from "../../types"
import { cutInLines } from "../contentEdit"

type WebinarHeroProps = {
  webinarTitle: string
  date: string
  presenters: { content: BlogAuthorType }[]
  image: StoryblokImageType
}

const WebinarHero = ({
  date,
  presenters,
  webinarTitle,
  image,
}: WebinarHeroProps) => {
  const webinarPresenters = presenters?.map((presenter, i) => (
    <div
      className="c-webinarHero__presenter"
      key={`${webinarTitle}-presenter-${i}`}
    >
      <StoryblokImage
        image={presenter?.content?.profilePhoto}
        className="c-webinarHero__presenterImage"
      />
      <span className="c-webinarHero__presenterName">
        {presenter?.content?.name}
      </span>
    </div>
  ))

  return (
    <div className="c-webinarHero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 c-webinarHero__content col-iconity-36">
            <h1 className="c-webinarHero__headline">
              {cutInLines(webinarTitle)}
            </h1>
            <div className="c-webinarHero__date">{date}</div>
            <div className="d-flex flex-wrap">{webinarPresenters}</div>
            <StoryblokImage image={image} className="c-webinarHero__image" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebinarHero
