import React from "react"
import { dateAsZohoString, useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import TextArea from "./formComponents/TextArea"
import CheckBox from "./formComponents/CheckBox"
import FileUpload from "./formComponents/FileUpload"
import { CommonFormProps } from "../types"
import SubmitButton from "./formComponents/SubmitButton"

const ZOHO_CANDIDATE_SUBMIT_URL =
  "https://forms.zohopublic.com/sarapodmolikova/form/Candidates/formperma/7bOMTzjNgXyuxWL2GvMU-cuTTK7nzX9eF16fhbHDGSE/htmlRecords/submit"

type CandidateFormFields = {
  firstName: string
  lastName: string
  email: string
  linkedInProfile: string
  message: string
  cvFile?: File
  newsletter: boolean
  marketing: boolean
  candidateStatus: string
  changedAt: string
  workPermit: boolean
}

const zohoCandidateFormFields: ZohoFormFields<CandidateFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  linkedInProfile: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: false,
  },
  message: {
    zohoFieldName: "MultiLine",
    initialValue: "",
    required: false,
  },
  cvFile: {
    zohoFieldName: "FileUpload",
    initialValue: undefined,
  },
  newsletter: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
  marketing: {
    zohoFieldName: "DecisionBox1",
    initialValue: false,
    required: false,
  },
  workPermit: {
    zohoFieldName: "DecisionBox2",
    initialValue: false,
    required: true,
  },
  candidateStatus: {
    zohoFieldName: "SingleLine10",
    initialValue: "NEW",
  },
  changedAt: {
    zohoFieldName: "Date",
    initialValue: dateAsZohoString(new Date()),
  },
}

export type CandidateFormProps = {
  linkedInProfileLabel: string
  messageLabel: string
  cvFileLabel: string
  browseCTA: string
  workPermitLabel: string
  workPermitLabelMissing: string
  formSource: string
} & CommonFormProps<CandidateFormFields>

const CandidateForm = ({
  titleForm,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  workPermitLabelMissing,
  hideNewsletter,
  submitErrorMessage,
  ...labels
}: CandidateFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<CandidateFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_CANDIDATE_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    otherSubmitError: workPermitLabelMissing,
    zohoFormFields: zohoCandidateFormFields,
  })

  return (
    <div className="form__container">
      <div className="form__row">
        <TextField
          name="firstName"
          label={labels.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
          className="textField--candidate"
        />
        <TextField
          name="lastName"
          label={labels.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
          className="textField--candidate"
        />
      </div>
      <div className="form__row">
        <TextField
          name="linkedInProfile"
          label={labels.linkedInProfileLabel}
          value={values.linkedInProfile}
          setValue={setValue}
          error={errors.linkedInProfile}
          clearError={clearError}
          className="textField--candidate"
        />
        <TextField
          name="email"
          label={labels.emailLabel}
          value={values.email}
          setValue={setValue}
          error={errors.email}
          clearError={clearError}
          className="textField--candidate"
        />
      </div>
      <TextArea
        name="message"
        label={labels.messageLabel}
        value={values.message}
        setValue={setValue}
        error={errors.message}
        clearError={clearError}
        className="textArea--small"
      />
      <FileUpload
        name="cvFile"
        label={labels.cvFileLabel}
        ctaLabel={labels.browseCTA}
        setValue={setValue}
      />
      <div className="form__checkBoxContainer">
        {!hideNewsletter ? (
          <CheckBox
            name="newsletter"
            label={labels.newsletterLabel}
            value={values.newsletter}
            setValue={setValue}
          />
        ) : null}
        <CheckBox
          name="marketing"
          label={labels.marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
        {labels.workPermitLabel && (
          <CheckBox
            name="workPermit"
            label={labels.workPermitLabel}
            value={values.workPermit}
            setValue={setValue}
          />
        )}
      </div>
      <SubmitButton
        isFetching={isFetching}
        doSubmit={doSubmit}
        submitLabel={submitLabel}
        submitError={submitError}
      />
    </div>
  )
}

export default CandidateForm
