import React from 'react'

import { cutInLines } from '../contentEdit'

import StoryblokImage from '../shared/StoryblokImage'

import { StoryblokImageType } from '../../types'

type PositionsHeroProps = {
  text: string,
  image: StoryblokImageType,
}

const PositionsHero = ({ text, image }: PositionsHeroProps) => {
  return (
    <div className="c-positionsHero">
      <StoryblokImage image={image} className="c-positionsHero__bg" />

      <div className="container">
        <div className="row">
          <div className="col-0 col-lg-2 col-xxxl-3" />

          <div className="col-10 col-sm-8 col-md-6 col-lg-4 col-xxl-3">
            <h1 className="c-positionsHero__headline">
              {cutInLines(text)}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PositionsHero
