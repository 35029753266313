import React from "react"
import fetchingGif from "../../assets/images/fetching.gif"

export type TextFieldProps = {
  isFetching: boolean;
  doSubmit: () => void;
  submitLabel: string;
  submitError: string;
  className?: string;
}

const SubmitButton = ({ isFetching, doSubmit, submitLabel, submitError, className }: TextFieldProps) => {

  return (
    <div className={`form__buttonContainer ${className ? className : ''}`}>
      {isFetching ? (
        <div className="form__fetchingBox">
          <img src={fetchingGif}/>
        </div>
      ) : (
        <button onClick={doSubmit} className="form__submit">
          {submitLabel}
        </button>
      )}
      {!!submitError && <p className="form__submit-error">{submitError}</p>}
    </div>
  )
}

export default SubmitButton
