import React from "react"
import classNames from "classnames"
import { render } from "storyblok-rich-text-react-renderer"
import StoryblokImage from "../shared/StoryblokImage"
import FindJobSelectionBoxes from "./FindJobSelectionBoxes"

const FindJobSelectionStaticBoxes = ({ cards, isAppSelected }: FindJobSelectionBoxes) => {

  const renderBoxClassNames = (isLeftAvailable, isRightAvailable) => (
    classNames("c-findJobSelection__boxItem", {
      "c-findJobSelection__boxItem--unavailable": isAppSelected ? !isRightAvailable : !isLeftAvailable
    })
  )

  const renderCards = cards.map((card, i) => {
    const content = isAppSelected
      ? (card?.textRight ? render(card.textRight) : "")
      : (card?.textLeft ? render(card.textLeft) : "")

    return (
      <div className="col-12 col-md-6 col-xl-4" key={`card-${i}`}>
        <div className={renderBoxClassNames(card.displayOnLeft, card.displayOnRight)}>
          <div className="c-findJobSelection__titleWrapper">
            <StoryblokImage image={card?.iconStandard} className="c-findJobSelection__boxIcon"/>
            <p className="c-findJobSelection__boxTitle">{card?.title}</p>
          </div>
          <div className="c-findJobSelection__boxContent">
            {content}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="c-findJobSelection__boxes c-findJobSelection__boxes--static row">
      {renderCards}
    </div>
  )
}

export default FindJobSelectionStaticBoxes
