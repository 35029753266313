import React from "react"
import classNames from "classnames"
import { getTranslatedUrl } from "../../../utils"

type LangSelectorType = {
  pathname: string
  activeLanguage: string
  isThankYou?: boolean
}

const LangSelector = ({
  pathname,
  activeLanguage,
  isThankYou,
}: LangSelectorType) => {
  const czechSlug =
    getTranslatedUrl(pathname, activeLanguage, "de", isThankYou) || "/de/"
  const englishSlug =
    getTranslatedUrl(pathname, activeLanguage, "default", isThankYou) || "/"

  return (
    <div className="topNav__langSelector ml-xl-4">
      <a
        className={classNames(
          "topNav__langSelector__lang hoverUnderlineAnimation",
          { topNav__langSelector__activeLang: activeLanguage === "default" }
        )}
        href={englishSlug}
      >
        EN
      </a>
      /
      <a
        className={classNames(
          "topNav__langSelector__lang hoverUnderlineAnimation",
          { topNav__langSelector__activeLang: activeLanguage === "de" }
        )}
        href={czechSlug}
      >
        DE
      </a>
    </div>
  )
}

export default LangSelector
