import React from 'react'

import heroBackground from '../../assets/images/position-detail-hero.png'

import LocationIcon from '../../assets/images/icons/location.svg'
import FileIcon from '../../assets/images/icons/file.svg'
import MoneyIcon from '../../assets/images/icons/money.svg'

import { StoryblokImageType } from '../../types'

type DetailHeroProps = {
  title: string,
  location: string,
  contractType: string,
  salary: string,
  image: StoryblokImageType,
}

const DetailHero = ({ title, location, contractType, salary, image }: DetailHeroProps) => {
  return (
    <section className="c-detailHero" style={{ backgroundImage: `url(${image?.filename})` }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-0 col-xxl-1" />
          <div className="col-12 col-md-7 col-lg-8 col-xl-6 col-xxl-5">
            <h1 className="c-detailHero__headline">
              {title}
            </h1>
            <ul className="c-detailHero__info">
              <li>
                <LocationIcon />
                {location}
              </li>
              <li>
                <FileIcon />
                {contractType}
              </li>
              <li>
                <MoneyIcon />
                {salary}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DetailHero
