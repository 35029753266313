import React from 'react'

import StoryblokImage from '../shared/StoryblokImage'

import LinkedIn from '../../assets/images/icons/linkedin-for-card.svg'
import Mail from '../../assets/images/icons/mail-for-card.svg'

import { PersonalIntroContentType } from '../../types'

type TeamCardProps = {
  content: PersonalIntroContentType,
}

const TeamCard = ({ content }: TeamCardProps) => {
  return (
    <div className="c-teamCard h-100">
      <div className="c-teamCard__top d-flex">
        <div className="c-teamCard__imageWrapper">
          <StoryblokImage image={content?.photo} className="c-teamCard__image" />
        </div>

        <div className="c-teamCard__details d-flex flex-column">
          <p className="c-teamCard__details-name">
            {content?.name}
          </p>
          <p className="c-teamCard__details-position">
            {content?.position}
          </p>
          {content?.phoneNumber && (
            <a href={`tel:${content.phoneNumber}`} className="c-teamCard__details-phone">
              {content.phoneNumber}
            </a>
          )}
          <div className="c-teamCard__details-socials d-flex align-items-center">
            {content?.linkedinAddress?.cached_url && (
              <a href={content.linkedinAddress.cached_url} target="_blank" rel="noopener noreferrer" className="c-teamCard__detailLink">
                <LinkedIn />
              </a>
            )}
            {content?.emailAddress?.cached_url && (
              <a href={`mailto:${content.emailAddress.cached_url}`} target="_blank" rel="noopener noreferrer" className="c-teamCard__detailLink">
                <Mail />
              </a>
            )}
          </div>
        </div>
      </div>

      <p className="c-teamCard__description">
        {content?.textPrimary}
      </p>
    </div>
  )
}

export default TeamCard
