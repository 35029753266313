import React from 'react'
import { companySocial } from "../../types"

import GetContact from './GetContact'
import GetForm from './GetForm'
import { GetInTouchFormProps } from "../../zohoForms/GetInTouchForm"

export type GetInTouchProps = {
  headline: string,
  subHeadline: string,
  companyEmail: string,
  companyPhone: string,
  companyName: string,
  companyInfo: any,
  companyFacebook: companySocial,
  companyLinkedin: companySocial,
  companyInstagram: companySocial,
} & GetInTouchFormProps;

const GetInTouch = ({
  headline,
  subHeadline,
  companyEmail,
  companyPhone,
  companyName,
  companyInfo,
  companyFacebook,
  companyLinkedin,
  companyInstagram,
  ...formProps
}: GetInTouchProps) => {
  return (
    <div className="c-getInTouch">
      <div className="container">
        <div className="row">
          <div className="col-0 col-xxl-1 order-xxl-1" />

          <div className="col-xl-5 col-xxl-4 order-2 order-xl-1 order-xxl-2">
            <GetContact
              headline={headline}
              subHeadline={subHeadline}
              companyEmail={companyEmail}
              companyPhone={companyPhone}
              companyName={companyName}
              companyInfo={companyInfo}
              companyFacebook={companyFacebook}
              companyLinkedin={companyLinkedin}
              companyInstagram={companyInstagram}
            />
          </div>

          <div className="col-0 col-xl-1 order-xl-2 order-xxl-3" />

          <div className="col-xl-6 col-xxl-5 order-1 order-xl-3 order-xxl-4">
            <GetForm
              headline={headline}
              subHeadline={subHeadline}
              {...formProps}
            />
          </div>

          <div className="col-0 col-xxl-1 order-xxl-5" />
        </div>
      </div>
    </div>
  )
}

export default GetInTouch
