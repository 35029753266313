import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { HeroSectionProps } from "./HeroCareer"
import { cutInLines } from "../contentEdit"

import talentPerson from "../../assets/images/HP-find-a-specialist.png"

const HeroTalents = ({ content }: HeroSectionProps) => {
  return (
    <div className="c-heroTalents d-flex flex-column flex-xl-row justify-content-start align-items-end align-items-xl-stretch h-100">
      <div className="c-heroTalents__content order-2 order-xl-1">
        <h2 className="c-heroTalents__headline">{cutInLines(content.title)}</h2>

        <p className="c-heroTalents__subHeadline">{content.text}</p>

        <a href={content.ctaTarget.cached_url} className="btnLearnMore">
          {content.ctaButton}
        </a>
      </div>

      <div className="c-heroTalents__imagePlace order-1 order-xl-2">
        <img src={talentPerson} alt="Find a specialist" />
        <div className="c-heroTalents__imagePlace-topCorners"></div>
      </div>
    </div>
  )
}

export default HeroTalents
