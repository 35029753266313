import React, { useState } from 'react'
import classNames from 'classnames'

import { TestimonialType } from '../../types'

import TestimonialSlider from './TestimonialSlider'
import GuarantorSlider from './GuarantorSlider'

export type TestimonialsProps = {
  isTestimonial: boolean,
  headline: string,
  testimonials: Array<TestimonialType>,
}

const Testimonials = ({ isTestimonial, headline, testimonials }: TestimonialsProps) => {
  const [activeSlide, setSlide] = useState(0)

  const showNext = () => {
    setSlide(activeSlide === (testimonials.length - 1) ? 0 : activeSlide + 1)
  }

  const showPrev = () => {
    setSlide(activeSlide === 0 ? (testimonials.length - 1) : (activeSlide - 1))
  }

  const showSlide = slideIndex => {
    setSlide(slideIndex)
  }

  const renderTestimonialClassNames = () => {
    return classNames({
      'c-testimonials': true,
      'c-testimonials--guarantor': !isTestimonial,
    })
  }

  return (
    <section className={renderTestimonialClassNames()}>
      <div className="container">

        <div className="row d-flex justify-content-center">
          <div className="col-xl-11 col-xxl-10">
            <h2 className="c-testimonials__headline">
              {headline}
            </h2>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-xl-11 col-xxl-10">
            {isTestimonial ? (
              <TestimonialSlider
                testimonials={testimonials}
                activeSlide={activeSlide}
                showNextSlide={showNext}
                showPrevSlide={showPrev}
                showSlide={showSlide}
              />
            ) : (
              <GuarantorSlider
                testimonials={testimonials}
                activeSlide={activeSlide}
                showNextSlide={showNext}
                showPrevSlide={showPrev}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonials
