import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout/Layout"
import CompanyJobsHero from "../components/companyJobs/CompanyJobsHero"
import CompanyListings from "../components/companyJobs/CompanyListings"
import PositionsConnection from "../components/openPositions/PositionsConnection"
import Testimonials from "../components/shared/Testimonials"

import { getLangFromPathname } from "../utils"
import {
  SeoMetaType,
  StoryblokImageType,
  JobDescriptionNodeType,
  ctaTarget,
  TestimonialType,
} from "../types"

type CompanyJobsPageProps = {
  pathname: string
  origin: string
  seoMeta: SeoMetaType
  headline: string
  titleText: any
  frameImage: StoryblokImageType
  simplityHeadline: string
  simplityText: any
  accurityHeadline: string
  accurityText: any
  title: string
  description: string
  ctaButton: string
  ctaTarget: ctaTarget
  background: StoryblokImageType
  titleTestimonials: string
  testimonials: TestimonialType[]
}

const sortJobsDesc = (a: JobDescriptionNodeType, b: JobDescriptionNodeType) =>
  a.position && b.position ? a.position - b.position : 0

const useLoadJobDescriptions = (
  activeLanguage: string
): JobDescriptionNodeType[] => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query JobDescriptionPosts {
          allStoryblokEntry(
            filter: { field_component: { eq: "JobDescription" } }
          ) {
            nodes {
              full_slug
              content
              lang
              position
            }
          }
        }
      `
    )

    return queryResult.allStoryblokEntry.nodes
      .map(node => {
        return {
          ...node,
          content: JSON.parse(node.content),
        } as JobDescriptionNodeType
      })
      .filter(q => q.lang === activeLanguage)
      .sort(sortJobsDesc)
  } catch (e) {
    console.log(e)
    console.error(
      "Static Query for Blog Overview failed!!! Falling back to dummy data."
    )
    return []
  }
}

function CompanyJobsPage({
  pathname,
  origin,
  seoMeta,
  headline,
  titleText,
  frameImage,
  simplityHeadline,
  simplityText,
  accurityHeadline,
  accurityText,
  title,
  description,
  ctaButton,
  ctaTarget,
  background,
  titleTestimonials,
  testimonials,
}: CompanyJobsPageProps) {
  const activeLanguage = getLangFromPathname(pathname)
  const allJobDescriptions = useLoadJobDescriptions(activeLanguage)
  const simplityHotPositions = allJobDescriptions.filter(
    job => job.content.simplityServicesPosition && job.content.hotPosition
  )
  const simplityPositions = allJobDescriptions.filter(
    job => job.content.simplityServicesPosition && !job.content.hotPosition
  )
  const accurityHotPositions = allJobDescriptions.filter(
    job => job.content.accurityPosition && job.content.hotPosition
  )
  const accurityPositions = allJobDescriptions.filter(
    job => job.content.accurityPosition && !job.content.hotPosition
  )

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      headerFix
      lightGreyBg
      contentClass="companyJobsPage"
      seoMeta={seoMeta}
    >
      <CompanyJobsHero
        title={headline}
        titleText={titleText}
        frameImage={frameImage}
      />
      <CompanyListings
        title={simplityHeadline}
        text={simplityText}
        hotPositions={simplityHotPositions}
        positions={simplityPositions}
      />
      <CompanyListings
        title={accurityHeadline}
        text={accurityText}
        hotPositions={accurityHotPositions}
        positions={accurityPositions}
      />
      <PositionsConnection
        title={title}
        description={description}
        ctaButton={ctaButton}
        ctaTarget={ctaTarget}
        background={background}
      />
      <Testimonials
        isTestimonial
        headline={titleTestimonials}
        testimonials={testimonials}
      />
    </Layout>
  )
}

export default CompanyJobsPage
