import React from "react"
import { SubmenuItemType } from "../../../types"

type SubmenuSimpleItemProps = SubmenuItemType;

const SubmenuSimpleItem = ({ label, link }: SubmenuSimpleItemProps) => {
  return (
    <li className="topNav__subMenuItem topNav__subMenuItem-simpleItem">
      <a href={link?.cached_url} className="hoverUnderlineAnimation">
        {label}
      </a>
    </li>
  )
}

export default SubmenuSimpleItem
