import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { cutInLines } from "../contentEdit"

import StoryblokImage from "../shared/StoryblokImage"

import { StoryblokImageType } from "../../types"

type TextBoxProps = {
  title?: string
  text?: any
  image?: StoryblokImageType
}

const TextBox = ({ title, text, image }: TextBoxProps) => {
  const headline = cutInLines(title)
  const content = render(text)

  return (
    <section className="c-textBox">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-xxl-6">
            <div className="c-textBox__content d-flex flex-column align-items-center">
              {title && (
                <h2 className="c-textBox__title secondary-heading text-center">
                  {headline}
                </h2>
              )}
              {text && (
                <div className="c-textBox__text text-center">{content}</div>
              )}
              <StoryblokImage image={image} className="c-textBox__image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextBox
