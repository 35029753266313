import React from "react"
import { ctaTarget } from "../../types"
import { checkLinkUrl, cutInLines } from "../contentEdit"
import NewsletterSignupForm, { NewsletterSignupFormProps } from "../../zohoForms/NewsletterSignupForm"

type NewsletterSignupHeroProps = {
  title: string;
  text: string;
  reusableForm: NewsletterSignupFormProps;
  thankYouPage: ctaTarget;
}

const NewsletterSignupHero = ({ reusableForm, thankYouPage, title, text }: NewsletterSignupHeroProps) => {
  return (
    <div className="c-newsletterSignupHero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 c-newsletterSignupHero__content col-iconity-36">
            <h1 className="c-newsletterSignupHero__headline">
              {cutInLines(title)}
            </h1>
            <div className="c-newsletterSignupHero__text">
              {text}
            </div>
          </div>
          <div className="col-12 col-xl-6 col-xxl-4 offset-xxl-1 c-newsletterSignupHero__formWrapper">
            {reusableForm && <NewsletterSignupForm
              {...reusableForm}
              successRedirectUrl={checkLinkUrl(thankYouPage.cached_url)}
            />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsletterSignupHero
