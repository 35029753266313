import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokImage from "../shared/StoryblokImage"
import LandingPageForm from "../../zohoForms/LandingPageForm"

import { StoryblokImageType } from "../../types"
import { WhitepaperFormProps } from "../../zohoForms/LandingPageForm"

type LandingPageHeroProps = {
  title: string
  text: any
  image: StoryblokImageType
  form: { content: WhitepaperFormProps }
}

const LandingPageHero = ({
  title,
  text,
  image,
  form,
}: LandingPageHeroProps) => {
  const description = render(text)

  return (
    <section className="c-landingPageHero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-xxxl-5">
            <div className="c-landingPageHero__content">
              <h1 className="pageHeading c-landingPageHero__title">{title}</h1>

              <div className="c-landingPageHero__text">{description}</div>

              <StoryblokImage
                image={image}
                className="c-landingPageHero__image"
              />
            </div>
          </div>

          <div className="col-xl-6 col-xxl-4 offset-xxxl-1">
            <div className="c-landingPageHero__form">
              <LandingPageForm {...form?.content} formSource={title} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingPageHero
