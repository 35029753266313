import React from 'react'

import StoryblokLink from '../shared/StoryblokLink'

import { FooterBoxItem } from '../../types'

type FooterColumnsProps = {
  boxes: Array<FooterBoxItem>,
}

const FooterColumns = ({ boxes }: FooterColumnsProps) => {
  const footerLists = boxes?.map((column, i) => (
    <div className="col-sm-6 col-lg-4 col-xl-2" key={`footer-column-${i}`}>
      <div className="footer__list">

        <div className="footer__list-topWrapper">
          <a href={column?.headerLink?.cached_url} className="footer__list-top">
            {column.headerLabel}
          </a>
        </div>

        <ul className="footer__listGroup">
          {column?.links?.map((linkItem, i) => (
            <li key={`column-link-${i}`}>
              <a href={linkItem?.link?.cached_url}>
                {linkItem.label}
              </a>
            </li>
          ))}
        </ul>

      </div>
    </div>
  ))

  return (
    <div className="row footer__listSection">
      {footerLists}
    </div>
  )
}

export default FooterColumns
