import Cookies, { CookieSetOptions } from "universal-cookie"
import { useEffect } from "react"

const ICONITY_DOMAIN = "iconity.ai"

export const EXTERNAL_REFERRER = "external_referrer"
export const UTM_SOURCE = "utm_source"
export const UTM_CAMPAIGN = "utm_campaign"
export const UTM_TERM = "utm_term"
export const UTM_CONTENT = "utm_content"
export const UTM_MEDIUM = "utm_medium"

const UTM_PARAMS = [UTM_SOURCE, UTM_CAMPAIGN, UTM_TERM, UTM_CONTENT, UTM_MEDIUM]

const currentCookies = new Cookies()

const UTM_COOKIE_OPTIONS: CookieSetOptions = {
  path: "/",
  expires: undefined,
  secure: false,
}

interface UtmParams {
  [utmParamName: string]: string
}

const useQueryStringParameter = (queryStringParameter: string) => {
  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search)
    return params.get(queryStringParameter)
  } else {
    return undefined
  }
}

export const useUtmTracking = () => {
  useEffect(() => {
    if (
      currentCookies.get(EXTERNAL_REFERRER) === undefined &&
      document.referrer &&
      !document.referrer.includes(ICONITY_DOMAIN)
    ) {
      currentCookies.set(
        EXTERNAL_REFERRER,
        document.referrer,
        UTM_COOKIE_OPTIONS
      )
    }

    UTM_PARAMS.forEach(utmParam => {
      const utmValue = useQueryStringParameter(utmParam)
      if (utmValue) {
        currentCookies.set(utmParam, utmValue, UTM_COOKIE_OPTIONS)
      }
    })
  }, [])
}

export const useUtmParameters = (): UtmParams => {
  const utmParams: UtmParams = {}

  if (typeof window !== "undefined") {
    utmParams[EXTERNAL_REFERRER] = currentCookies.get(EXTERNAL_REFERRER)

    UTM_PARAMS.forEach(utmParam => {
      utmParams[utmParam] = currentCookies.get(utmParam)
    })
  }

  return utmParams
}
