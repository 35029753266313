import React, { useRef, useEffect } from 'react'
import Slider from 'react-slick'

import StoryblokImage from '../shared/StoryblokImage'

import NextArrow from '../../assets/images/icons/guarantor-arrow-right.svg'
import PrevArrow from '../../assets/images/icons/guarantor-arrow-left.svg'

import { TestimonialType } from '../../types'

type GuarantorSliderProps = {
  activeSlide: number,
  showNextSlide: () => void,
  showPrevSlide: () => void,
  testimonials: Array<TestimonialType>,
}

const GuarantorSlider = ({ activeSlide, showNextSlide, showPrevSlide, testimonials }: GuarantorSliderProps) => {
  const slider = useRef(null)

  useEffect(() => {
    slider.current.slickGoTo(activeSlide)
  }, [activeSlide])

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    draggable: false,
  }

  const slides = testimonials.map((slide, i) => (
    <div className="c-guarantorSlider__item d-flex" key={`guarantor-slide-${i}`}>
      <div className="c-guarantorSlider__text">
        <div className="c-guarantorSlider__description order-1">
          <p>
            {slide.content?.text}
          </p>
        </div>

        <div className="c-guarantorSlider__who order-3 order-md-2">
          <p className="c-guarantorSlider__name">
            {slide.content?.name}
          </p>
          <p className="c-guarantorSlider__position">
            {slide.content?.position}
          </p>
          <StoryblokImage image={slide.content?.companyLogo} className="c-guarantorSlider__logo" />
        </div>

        <div className="c-guarantorSlider__mobileImages d-lg-none order-2 order-md-3">
          <StoryblokImage image={slide.content?.image} className="c-guarantorSlider__mobileImage" />
          <div className="c-guarantorSlider__buttons d-flex align-items-center">
            <button className="c-guarantorSlider__singleButton d-flex align-items-center" onClick={() => showPrevSlide()}>
              <PrevArrow />
            </button>

            <button className="c-guarantorSlider__singleButton d-flex align-items-center" onClick={() => showNextSlide()}>
              <NextArrow />
            </button>
          </div>
        </div>
      </div>

      <div className="c-guarantorSlider__photo">
        <StoryblokImage image={slide.content?.image} />
        <div className="c-guarantorSlider__buttons d-flex align-items-center">
          <button className="c-guarantorSlider__singleButton d-flex align-items-center" onClick={() => showPrevSlide()}>
            <PrevArrow />
          </button>

          <button className="c-guarantorSlider__singleButton d-flex align-items-center" onClick={() => showNextSlide()}>
            <NextArrow />
          </button>
        </div>
      </div>
    </div>
  ))

  return (
    <div className="c-guarantorSlider">
      <Slider ref={slider} {...settings}>
        {slides}
      </Slider>
    </div>
  )
}

export default GuarantorSlider
