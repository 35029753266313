import React, { useState } from 'react'

import FindJobSelectionHead from './FindJobSelectionHead'
import FindJobSelectionSwitch from './FindJobSelectionSwitch'
import FindJobSelectionTitles from './FindJobSelectionTitles'
import FindJobSelectionLogos from './FindJobSelectionLogos'
import FindJobSelectionBoxes from './FindJobSelectionBoxes'

import { FindJobLogos, FindJobCards } from '../../types'

type FindJobSelectionProps = {
  tilesHeader: string,
  tilesText: string,
  titleLeft: string,
  titleRight: string,
  logosLeft: Array<FindJobLogos>,
  logosRight: Array<FindJobLogos>,
  belowLogoText: string,
  cards: Array<FindJobCards>,
  BoxesComponent?: React.ReactElement,
}

const FindJobSelection = ({
                            tilesHeader,
                            tilesText,
                            titleLeft,
                            titleRight,
                            logosLeft,
                            logosRight,
                            belowLogoText,
                            cards,
                            BoxesComponent = FindJobSelectionBoxes
}: FindJobSelectionProps) => {
  const [isAppSelected, setSelected] = useState(false)

  const handleSwitchClick = () => {
    setSelected(!isAppSelected)
  }

  return (
    <div className="c-findJobSelection">
      <div className="container">
        <FindJobSelectionHead tilesHeader={tilesHeader} tilesText={tilesText} />

        <div className="row justify-content-center">
          <div className="col-xl-10 col-xxl-8">
            <div className="c-findJobSelection__app">

              <FindJobSelectionSwitch checked={isAppSelected} handleClick={handleSwitchClick} />

              <FindJobSelectionTitles
                titleLeft={titleLeft}
                titleRight={titleRight}
                isAppSelected={isAppSelected}
                handleSwitchClick={handleSwitchClick}
              />

              <FindJobSelectionLogos
                logos={isAppSelected ? logosRight : logosLeft}
                belowLogoText={belowLogoText}
              />

              {React.createElement(BoxesComponent, {cards, isAppSelected})}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindJobSelection
