import React, { useState } from "react"
import { BlogPostType, CtaBanner, SeoMetaType } from "../types"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout/Layout"
import ResourceCardsContainer from "../components/shared/ResourceCardsContainer"
import Crossroads from "../components/shared/Crossroads"
import { getLangFromPathname } from "../utils"
import BlogListingHero from "../components/blogListing/BlogListingHero"

type BlogListingPageProps = {
  seoMeta: SeoMetaType
  pathname: string
  origin: string

  /**
   * uuid of featured blog post
   */
  featuredPost: string
  featuredPostCtaButton: string
  featuredPostTag: string
  listingTitle: string
  loadMoreBtnLabel: string
  numberOfVisibleBlogs: number
  reusableCtaBanner: { content: CtaBanner }
}

type BlogPostNodeType = {
  full_slug: string
  published_at: string | null
  content: BlogPostType
  lang: string
  uuid: string
};

/**
 * Sorting function to sort blog posts in descending order
 * @param a
 * @param b
 */
const sortBlogsDesc = (a: BlogPostNodeType, b: BlogPostNodeType) =>
  a.published_at && b.published_at
    ? b.published_at.localeCompare(a.published_at)
    : 0

const useLoadBlogPosts = (activeLanguage: string): BlogPostNodeType[] => {
  try {
    const queryResult = useStaticQuery(
      graphql`
        query BlogListingPosts {
          allStoryblokEntry(filter: { field_component: { eq: "Blog" } }) {
            nodes {
              full_slug
              published_at
              content
              lang
              uuid
            }
          }
        }
      `
    )

    return queryResult.allStoryblokEntry.nodes
      .map(node => {
        return {
          ...node,
          content: JSON.parse(node.content)
        } as BlogPostNodeType
      })
      .filter(q => !!q.published_at && q.lang === activeLanguage)
      .sort(sortBlogsDesc)
  } catch (e) {
    console.log(e)
    console.error(
      "Static Query for Blog Overview failed!!! Falling back to dummy data."
    )
    return []
  }
}

const BlogListingPage = ({
                           loadMoreBtnLabel,
                           reusableCtaBanner,
                           featuredPost,
                           featuredPostCtaButton,
                           featuredPostTag,
                           listingTitle,
                           seoMeta,
                           pathname,
                           numberOfVisibleBlogs,
                           origin
                         }: BlogListingPageProps) => {
  const activeLanguage = getLangFromPathname(pathname)
  numberOfVisibleBlogs = parseInt(numberOfVisibleBlogs.toString())
  const [visibleBlogsCnt, setVisibleBlogsCnt] = useState(numberOfVisibleBlogs)

  const allBlogPosts = useLoadBlogPosts(activeLanguage)
  const featuredBlogPost =
    allBlogPosts.find(q => q.uuid === featuredPost) ||
    allBlogPosts[0]

  let visibleBlogPosts = allBlogPosts
    .filter(blog => blog.uuid !== featuredBlogPost.uuid)
    .slice(0, visibleBlogsCnt)

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      contentClass="blogListingPage"
      seoMeta={seoMeta}
    >
      {/* just to check if we have valid content data. Mainly to not break storyblok editor when content changes */}
      {featuredBlogPost?.content?.blogTitle && (
        <BlogListingHero
          title={featuredBlogPost.content.blogTitle}
          image={featuredBlogPost.content.headerImage}
          leadParagraph={featuredBlogPost.content.leadParagraph}
          authors={featuredBlogPost.content.authors}
          tag={featuredPostTag}
          ctaButton={featuredPostCtaButton}
          ctaButtonLink={featuredBlogPost.full_slug}
        />
      )}

      <ResourceCardsContainer
        title={listingTitle}
        cards={visibleBlogPosts}
        cardButton={"SHOW ME THE POST"}
        cardTag={"Blog post"}
        background={"topRight"}
      />

      <div className="c-blogListing__showMore text-center">
        {visibleBlogsCnt < allBlogPosts.length && (
          <span
            onClick={() => setVisibleBlogsCnt(q => q + numberOfVisibleBlogs)}
            className="btnSecondary hover-bcgUnderline"
          >
            {loadMoreBtnLabel}
          </span>
        )}
      </div>

      {reusableCtaBanner.content && (
        <Crossroads ctaBanner={reusableCtaBanner.content}/>
      )}
    </Layout>
  )
}

export default BlogListingPage
