import React from "react"

import { HeaderType, DescriptionType, TestimonialType, SeoMetaType } from "../types"

import Layout from '../components/layout/Layout'
import Hero from '../components/home/Hero'
import Testimonials from '../components/shared/Testimonials'
import Introduction from '../components/home/Introduction'

export type HomePageProps = {
  title: string;
  text: string;
  header: Array<HeaderType>,
  description: Array<DescriptionType>,
  testimonial: Array<TestimonialType>,
  testimonialTitle: string,
  pathname: string,
  origin: string,
  seoMeta: SeoMetaType,
}

function Home({ title, text, header, description, testimonial, testimonialTitle, pathname, origin, seoMeta }: HomePageProps) {
  return (
    <Layout pathname={pathname} origin={origin} seoMeta={seoMeta}>
      <Hero headline={title} subHeadline={text} header={header} />
      <Introduction content={description[0]} />
      <Testimonials isTestimonial testimonials={testimonial} headline={testimonialTitle} />
    </Layout>
  )
}

export default Home
