import React from 'react'
import Switch from 'react-switch'

type FindJobSelectionSwitchProps = {
  checked: boolean,
  handleClick: () => void,
}

const FindJobSelectionSwitch = ({ checked, handleClick }: FindJobSelectionSwitchProps) => {
  return (
    <div className="d-flex justify-content-center">
      <Switch
        onChange={() => handleClick()}
        checked={checked}
        className="c-findJobSelection__switch"
        checkedIcon={false}
        uncheckedIcon={false}
        onColor="#DFDFDF"
        offColor="#DFDFDF"
        onHandleColor="#E43D30"
        offHandleColor="#E43D30"
        height={26}
        width={50}
      />
    </div>
  )
}

export default FindJobSelectionSwitch
