import React from "react"
import { SubmenuItemType } from "../../../types"

type SubmenuButtonProps = SubmenuItemType;

const SubmenuButton = ({ label, link }: SubmenuButtonProps) => {
  return (
    <li className="topNav__subMenuItem topNav__subMenuItem-button">
      <a href={link?.cached_url} className="btnPrimary">
        {label}
      </a>
    </li>
  )
}

export default SubmenuButton
