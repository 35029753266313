import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { cutInLines } from "../contentEdit"

import StoryblokImage from "../shared/StoryblokImage"

import { StoryblokImageType } from "../../types"

type DiagramProps = {
  title: string
  text: any
  image: StoryblokImageType
}

const Diagram = ({ title, text, image }: DiagramProps) => {
  const headline = cutInLines(title)
  const content = render(text)

  return (
    <section className="c-diagram">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xxl-5 offset-xxl-1">
            <div className="c-diagram__content">
              {title && (
                <h2 className="c-diagram__title secondary-heading">
                  {headline}
                </h2>
              )}
              {text && <div className="c-diagram__text">{content}</div>}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xxl-10 offset-xxl-1">
            <div className="c-diagram__imageWrapper">
              <StoryblokImage image={image} className="c-diagram__image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Diagram
