import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { StoryblokImageType } from "../../types"
import { render } from "storyblok-rich-text-react-renderer"

type WhitepaperContentProps = {
  contentHeader: string;
  content: string;
  contentImage: StoryblokImageType;
}

const WhitepaperContent = ({ content, contentHeader, contentImage }: WhitepaperContentProps) => {

  return (
    <div className="c-whitepaperContent">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 col-iconity-36">
            <h3 className="c-whitepaperContent__headline">{contentHeader}</h3>
            <div className="c-whitepaperContent__text c-richText">{render(content)}</div>
          </div>
          <div className="col-12 col-xl-6 col-xxl-5 col-iconity-36">
            <StoryblokImage image={contentImage} className="c-whitepaperContent__image d-none d-xl-inline"/>
          </div>
        </div>
      </div>
      <StoryblokImage image={contentImage} className="c-whitepaperContent__image d-xl-none"/>
    </div>
  )
}

export default WhitepaperContent
