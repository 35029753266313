import React from "react"
import Layout from "../components/layout/Layout"
import { ctaTarget, SeoMetaType, StoryblokImageType } from "../types"
import WebinarContent from "../components/webinar/WebinarContent"
import { NewsletterSignupFormProps } from "../zohoForms/NewsletterSignupForm"
import NewsletterSignupHero from "../components/newsletterSignup/NewsletterSignupHero"

type NewsletterSignupPageProps = {
  seoMeta: SeoMetaType;
  pathname: string;
  origin: string;
  title: string;
  text: string;
  reusableForm: { content: NewsletterSignupFormProps };
  contentHeader: string;
  content: string;
  headerImageThumb: StoryblokImageType;
  thankYouPage: ctaTarget;
}

const NewsletterSignupPage = ({
                                seoMeta,
                                pathname,
                                origin,
                                content,
                                contentHeader,
                                reusableForm,
                                thankYouPage,
                                headerImageThumb,
                                title,
                                text
                              }: NewsletterSignupPageProps) => {

  return (
    <Layout pathname={pathname} origin={origin} contentClass="newsletterSignupPage" seoMeta={seoMeta}>
      <NewsletterSignupHero
        reusableForm={reusableForm?.content}
        thankYouPage={thankYouPage}
        title={title}
        text={text}
      />
      <WebinarContent
        content={content}
        contentHeader={contentHeader}
      />
    </Layout>
  )
}

export default NewsletterSignupPage
