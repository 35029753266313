import * as React from "react"
import classnames from "classnames"
import { ctaTarget, ResourceCardType } from "../../types"
import ResourceCard, { ResourceCardProps } from "./ResourceCard"
import { cutInLines } from "../contentEdit"

export type ResourceCardsContainerProps = {
  title: string
  cards: ResourceCardType[]
  cta?: string
  ctaLink?: ctaTarget
  cardButton: string
  cardTag: string
  background?: "topRight" | "topLeft" | "fullWidth"
  topCards?: boolean
}

const ResourceCardsContainer = ({
  cardButton,
  cards,
  cardTag,
  cta,
  ctaLink,
  title,
  background,
  topCards,
}: ResourceCardsContainerProps) => {
  const resources = cards
    .map(card => mapResourceToCardType(card, cardTag, cardButton))
    .filter(props => !!props)
    .map((props, idx) => (
      <ResourceCard {...props} key={idx} cardCount={cards.length} />
    ))

  let containerClassName = ""
  switch (background) {
    case "topLeft":
      containerClassName = "c-resourceCardsContainer--bgTopLeft"
      break
    case "topRight":
      containerClassName = "c-resourceCardsContainer--bgTopRight"
      break
    case "fullWidth":
      containerClassName = "c-resourceCardsContainer--bgFullWidth"
      break
  }

  if (topCards) {
    containerClassName += " c-resourceCardsContainer--topCards"
  }

  return (
    <div className={classnames("c-resourceCardsContainer", containerClassName)}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xxl-10 col-iconity-36">
            {title && (
              <h2 className="c-resourceCardsContainer__title">
                {cutInLines(title)}
              </h2>
            )}
            <div className="c-resourceCardsContainer__cardsWrapper">
              {resources}
            </div>
            {cta && ctaLink?.cached_url && (
              <a href={ctaLink.cached_url} className="btnSecondary">
                {cta}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceCardsContainer

const mapResourceToCardType = (
  resource: ResourceCardType,
  cardTag: string,
  cardButton: string
): ResourceCardProps => {
  if (!resource.content) {
    return null
  }

  const commonProps: Partial<ResourceCardProps> = {
    link: resource.full_slug,
    tag: cardTag,
    ctaButton: cardButton,
  }

  switch (resource.content.component) {
    case "Blog":
      return {
        ...commonProps,
        title: resource.content.blogTitle,
        image: resource.content.headerImageThumb?.filename
          ? resource.content.headerImageThumb
          : resource.content.headerImage,
      }
    case "Whitepaper":
      return {
        ...commonProps,
        title: resource.content.whitepaperTitle,
        image: resource.content.headerImageThumb?.filename
          ? resource.content.headerImageThumb
          : resource.content.headerImage,
      }
    case "Webinar":
      return {
        ...commonProps,
        title: resource.content.webinarTitle,
        date: resource.content.date,
        image: resource.content.headerImageThumb,
      }
    case "onDemandWebinar":
      return {
        ...commonProps,
        title: resource.content.title,
        date: resource.content.watchTime,
        image: resource.content.youtubeImage,
      }
    default:
      console.log(`Missing mapping for resource card`, resource.content)
      return null
  }
}
