import React from "react"
import classNames from "classnames"

import "../../assets/styles/start.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Header from "./Header"
import Footer from "./Footer"
import { useUtmTracking } from "../../cookieLogic/utmTrackingLogic"
import { SeoMetaType } from "../../types"
import SeoMeta from "../seoMeta/SeoMeta"
import { getLangFromPathname } from "../../utils"

export type LayoutProps = {
  headerFix?: boolean
  lightGreyBg?: boolean
  contentClass?: string
  pathname: string
  origin: string
  seoMeta?: SeoMetaType
  children: React.ReactNode
  isThankYou?: boolean
  hasOverlay?: boolean
  sticky?: boolean
}

const Layout = ({
  headerFix,
  lightGreyBg,
  contentClass,
  pathname,
  origin,
  seoMeta,
  children,
  isThankYou,
  hasOverlay,
  sticky,
}: LayoutProps) => {
  useUtmTracking()
  const activeLanguage = getLangFromPathname(pathname)

  const renderPageClassNames = () => {
    return classNames({
      "header-fixed-fixation": headerFix,
      "light-grey-background": lightGreyBg,
      "no-overflow-hidden": sticky,
      [`${contentClass}`]: Boolean(contentClass),
    })
  }

  const layoutContent = (
    <>
      <SeoMeta
        {...seoMeta}
        pathname={pathname}
        origin={origin}
        activeLanguage={activeLanguage}
        isThankYou={isThankYou}
      />
      <Header
        activeLanguage={activeLanguage}
        pathname={pathname}
        isThankYou={isThankYou}
      />
      <main className={renderPageClassNames()}>{children}</main>
      <Footer activeLanguage={activeLanguage} lightGreyBg={lightGreyBg} />
    </>
  )

  let finalReturn = layoutContent
  if (hasOverlay) {
    finalReturn = <div className="position-relative">{layoutContent}</div>
  }

  return finalReturn
}

export default Layout
