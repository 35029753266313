import React from 'react'
import classNames from 'classnames'
import StoryblokImage from '../shared/StoryblokImage'
import { FindJobHeader } from '../../types'

type FindJobHeroProps= {
  header: FindJobHeader,
  isClientSection?: boolean,
}

const FindJobHero = ({ header, isClientSection }: FindJobHeroProps) => {
  const renderClassNames = () => (
    classNames({
      'c-findJobHero': true,
      'c-findJobHero--specialist': isClientSection
    })
  )

  return (
    <div className={renderClassNames()}>
      <StoryblokImage image={header.image} className="c-findJobHero__bg"/>

      <div className="container">
        <div className="row">
          <div className="col-lg-1" />

          <div className="col-12 col-md-7 col-lg-8 col-xl-6 col-xxl-5 col-xxxl-4">
            <div className="c-findJobHero__content">
              <h1 className="c-findJobHero__headline">
                {header.title}
              </h1>
              <p className="c-findJobHero__text">
                {header.text}
              </p>
              <a href={header.ctaTarget.cached_url} className="btnPrimary btn--hoverMove">
                {header.ctaButton}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindJobHero
