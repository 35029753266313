import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import CheckBox from "./formComponents/CheckBox"
import { CommonFormProps } from "../types"
import SubmitButton from "./formComponents/SubmitButton"

const ZOHO_WHITEPAPER_SUBMIT_URL =
  "https://form.iconity.ai/simplitygroup/form/IconityWhitepaper/formperma/SugOqnYBSHZehzc8mOYycZvYGlzrL8dXfYdgqrETtqE/htmlRecords/submit"

type ClientFormFields = {
  firstName: string
  lastName: string
  email: string
  company: string
  marketing: boolean
}

const zohoClientFormFields: ZohoFormFields<ClientFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  company: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: true,
  },
  marketing: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
}

export type WhitepaperFormProps = {
  companyLabel: string
  docPath: string
  docName: string
} & CommonFormProps<ClientFormFields>

const WhitepaperForm = ({
  titleForm,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  hideNewsletter,
  docName,
  docPath,
  ...labels
}: WhitepaperFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmitAndDownloadDocument,
  } = useZohoFormLogic<ClientFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_WHITEPAPER_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoClientFormFields,
  })

  return (
    <div className="form__container">
      {titleForm && <h3 className="form__headline">{titleForm}</h3>}
      <div className="form__row">
        <TextField
          name="firstName"
          label={labels.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={labels.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <TextField
        name="company"
        label={labels.companyLabel}
        value={values.company}
        setValue={setValue}
        error={errors.company}
        clearError={clearError}
      />
      <TextField
        name="email"
        label={labels.emailLabel}
        value={values.email}
        setValue={setValue}
        error={errors.email}
        clearError={clearError}
      />
      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={labels.marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <SubmitButton
        isFetching={isFetching}
        doSubmit={() => doSubmitAndDownloadDocument(docPath, docName)}
        submitLabel={submitLabel}
        submitError={submitError}
      />
    </div>
  )
}

export default WhitepaperForm
