import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const styles = require('./GoogleMapStyles.json')

export type MapProps = {
  googleMapURL: string,
}

const Map = withScriptjs(
  withGoogleMap( (props: MapProps) => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{
        lat: 49.196,
        lng: 16.608
      }}
      defaultOptions={{
        disableDefaultUI: true, // disable default map UI
        draggable: true, // make map draggable
        keyboardShortcuts: false, // disable keyboard shortcuts
        scaleControl: true, // allow scale controle
        scrollwheel: true, // allow scroll wheel
        styles: styles // change default map styles
      }}
    >
      <Marker
        position={{
          lat: 49.1957211, // latitude to position the marker
          lng: 16.6081961 // longitude to position the marker
        }}
      />
    </GoogleMap>
  ))
)

export default Map
