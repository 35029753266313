import React from "react"
import { useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import TextArea from "./formComponents/TextArea"
import CheckBox from "./formComponents/CheckBox"
import Radio from "./formComponents/Radio"
import { CommonFormProps } from "../types"
import SubmitButton from "./formComponents/SubmitButton"

const ZOHO_GET_IN_TOUCH_SUBMIT_URL =
  "https://forms.zohopublic.com/sarapodmolikova/form/GetinTouch/formperma/5-AarLEQVHG0vBnfXXQ0UCPE9VfqTY-4FHiZcNJJF6M/htmlRecords/submit"

export type GetInTouchFormFields = {
  clientOrCandidate: string
  firstName: string
  lastName: string
  email: string
  message: string
  newsletter: boolean
  marketing: boolean
}

const zohoGetInTouchFormFields: ZohoFormFields<GetInTouchFormFields> = {
  clientOrCandidate: {
    zohoFieldName: "Radio",
    initialValue: undefined,
    required: true,
  },
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  message: {
    zohoFieldName: "MultiLine",
    initialValue: "",
    required: true,
  },
  newsletter: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
  marketing: {
    zohoFieldName: "DecisionBox1",
    initialValue: false,
    required: false,
  },
}

export type GetInTouchFormProps = {
  isClientLabel: string
  isCandidateLabel: string
  clientMarketingLabel: string
  candidateMarketingLabel: string
  messageLabel: string
} & CommonFormProps<GetInTouchFormFields>

const GetInTouchForm = ({
  isClientLabel,
  isCandidateLabel,
  clientMarketingLabel,
  candidateMarketingLabel,
  titleForm,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  messageLabel,
  newsletterLabel,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  hideNewsletter,
}: GetInTouchFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<GetInTouchFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_GET_IN_TOUCH_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoGetInTouchFormFields,
  })

  return (
    <div className="form__container">
      <h3 className="form__headline">{titleForm}</h3>
      <div className="form__row">
        <Radio
          id="client"
          name="clientOrCandidate"
          label={isClientLabel}
          value={"Client"}
          setValue={setValue}
          error={errors.clientOrCandidate}
          clearError={clearError}
        />
        <Radio
          id="candidate"
          name="clientOrCandidate"
          label={isCandidateLabel}
          value={"Candidate"}
          setValue={setValue}
          error={errors.clientOrCandidate}
          clearError={clearError}
        />
      </div>
      <div
        className={`form__curtain ${values.clientOrCandidate ? "open" : ""}`}
      >
        <div className="form__row">
          <TextField
            name="firstName"
            label={firstNameLabel}
            value={values.firstName}
            setValue={setValue}
            error={errors.firstName}
            clearError={clearError}
          />
          <TextField
            name="lastName"
            label={lastNameLabel}
            value={values.lastName}
            setValue={setValue}
            error={errors.lastName}
            clearError={clearError}
          />
        </div>
        <TextField
          name="email"
          label={emailLabel}
          value={values.email}
          setValue={setValue}
          error={errors.email}
          clearError={clearError}
        />
        <TextArea
          name="message"
          label={messageLabel}
          value={values.message}
          setValue={setValue}
          error={errors.message}
          clearError={clearError}
          className="mt-n3"
        />
        <div className="form__checkBoxContainer">
          {!hideNewsletter ? (
            <CheckBox
              name="newsletter"
              label={newsletterLabel}
              value={values.newsletter}
              setValue={setValue}
            />
          ) : null}
          <CheckBox
            name="marketing"
            label={
              values.clientOrCandidate === "Client"
                ? clientMarketingLabel
                : candidateMarketingLabel
            }
            value={values.marketing}
            setValue={setValue}
          />
        </div>
        <SubmitButton
          isFetching={isFetching}
          doSubmit={doSubmit}
          submitLabel={submitLabel}
          submitError={submitError}
          className={"form__buttonContainer--getInTouch"}
        />
      </div>
    </div>
  )
}

export default GetInTouchForm
