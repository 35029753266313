import React from "react"
import { Helmet } from "react-helmet"
import { SeoMetaType } from "../../types"

import { getTranslatedUrl } from "../../utils"

type ExtendedSeoMetaType = {
  pathname: string
  origin: string
  activeLanguage: string
  isThankYou: boolean
} & SeoMetaType

const SeoMeta = ({
  title,
  description,
  canonicalLink,
  ogImage,
  noIndex,
  pathname,
  origin,
  activeLanguage,
  isThankYou,
}: ExtendedSeoMetaType) => {
  const meta = []
  const link = []

  const czechSlug = getTranslatedUrl(pathname, activeLanguage, "cz", isThankYou)
  const englishSlug = getTranslatedUrl(
    pathname,
    activeLanguage,
    "default",
    isThankYou
  )

  meta.push({ name: `twitter:card`, content: `summary_large_image` })

  if (title) {
    meta.push({ name: `og:title`, content: title })
    meta.push({ property: `og:title`, content: title })
    meta.push({ name: `twitter:title`, content: title })
  }

  if (description) {
    meta.push({ name: `description`, content: description })
    meta.push({ name: `og:description`, content: description })
    meta.push({ property: `og:description`, content: description })
    meta.push({ name: `twitter:description`, content: description })
  }

  if (canonicalLink) {
    meta.push({ name: `og:url`, content: canonicalLink })
    meta.push({ property: `og:url`, content: canonicalLink })
    link.push({ rel: "canonical", href: canonicalLink })
  }

  if (ogImage) {
    meta.push({ name: `og:image`, content: ogImage })
    meta.push({ property: `og:image`, content: ogImage })
    meta.push({ name: `twitter:image`, content: ogImage })
  }

  if (noIndex) {
    meta.push({ name: "robots", content: "noindex" })
  }

  if (czechSlug && !isThankYou) {
    link.push({ rel: `alternate`, href: origin + czechSlug, hrefLang: `cs` })
  }

  if (englishSlug && !isThankYou) {
    link.push({ rel: `alternate`, href: origin + englishSlug, hrefLang: `en` })
  }

  return (
    <>
      <Helmet
        title={title}
        script={[
          {
            innerHTML: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
                wait_for_update: 2000,
            });
            gtag("set", "ads_data_redaction", true);`,
          },
        ]}
        meta={meta}
        link={link}
      />
    </>
  )
}

export default SeoMeta
