import React from "react"
import classNames from "classnames"
import { NODE_IMAGE, render } from "storyblok-rich-text-react-renderer"

type BlogPostTextProps = {
  leadParagraph: string
  blogBody: string
}

const BlogPostText = ({ leadParagraph, blogBody }: BlogPostTextProps) => {
  return (
    <section className={classNames("c-blogPostText")}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-7 c-blogPostContent c-richText">
            <div className="c-blogPostText__leadParagraph">
              {leadParagraph}
            </div>
            {render(blogBody, {
              nodeResolvers: {
                [NODE_IMAGE]: (children, props) => (
                  <img className="c-blogPostText__image" {...props} />
                )
              }
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
export default BlogPostText
