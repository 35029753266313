import React from "react"

import Layout from '../components/layout/Layout'
import MeetTeamHero from '../components/meetTeam/MeetTeamHero'
import MeetTeamMembers from '../components/meetTeam/MeetTeamMembers'
import Crossroads from '../components/shared/Crossroads'

import { CtaBanner, HeaderType, SeoMetaType, PersonalIntroType } from "../types"

type MeetTeamPageProps = {
  pathname: string,
  origin: string,
  seoMeta: SeoMetaType,
  header: Array<HeaderType>,
  ctaBanner: Array<CtaBanner>,
  headPersonalIntro: Array<PersonalIntroType>,
  personalIntro: Array<PersonalIntroType>,
}

function MeetTeamPage({ pathname, origin, seoMeta, header, ctaBanner, headPersonalIntro, personalIntro }: MeetTeamPageProps) {
  return (
    <Layout pathname={pathname} origin={origin} headerFix lightGreyBg contentClass="meetTeamPage" seoMeta={seoMeta} >
      <MeetTeamHero content={header[0]} />
      <MeetTeamMembers manager={headPersonalIntro} team={personalIntro} />
      <Crossroads ctaBanner={ctaBanner[0]} />
    </Layout>
  )
}

export default MeetTeamPage
