import React from 'react'

import LinkedinLogo from '../../assets/images/icons/linkedin.svg'

import { RecruiterCardContentType } from '../../types'

type DetailPatronCardProps = {
  content: RecruiterCardContentType,
}

const DetailPatronCard = ({ content }: DetailPatronCardProps) => {
  return (
    <div className="c-detailPatronCard d-flex flex-column align-items-center">
      <h3 className="c-detailPatronCard__position text-center">
        {content?.title}
      </h3>
      <div className="c-detailPatronCard__imageWrapper" style={{ backgroundImage: `url(${content?.image?.filename})` }} />
      <p className="c-detailPatronCard__name">
        {content?.name}
      </p>
      {content?.phoneNumber && (
        <a href={`tel:${content?.phoneNumber}`} className="c-detailPatronCard__phone">
          {content?.phoneNumber}
        </a>
      )}
      {content?.linkedinAddress?.cached_url && (
        <a href={content.linkedinAddress.cached_url} target="_blank" rel="noopener noreferrer" className="c-detailPatronCard__linkedin">
          <LinkedinLogo />
        </a>
      )}
      <p className="c-detailPatronCard__description text-center">
        {content?.text}
      </p>
    </div>
  )
}

export default DetailPatronCard
