import React from "react"
import { PersonalIntroContentType } from "../../types"
import TeamCard from "../shared/TeamCard"
import ClientForm, { ClientFormProps } from "../../zohoForms/ClientForm"

type FindSpecialistContactSectionProps = {
  recruiters: PersonalIntroContentType[]
} & ClientFormProps

const FindSpecialistContactSection = ({
  recruiters = [],
  ...formProps
}: FindSpecialistContactSectionProps) => {
  const teamCards = recruiters.map((recruiter, i) => {
    const content: PersonalIntroContentType = {
      ...recruiter,
      textPrimary: "",
      textSecondary: "",
    }
    return <TeamCard content={content} key={i} />
  })

  return (
    <div className="c-findSpecialist__contactSection" id="contactForm">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-xl-5 offset-xl-1 col-xxl-4">
            <div className="c-findSpecialist__contactSection-cards">
              {teamCards}
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 col-xxl-5 offset-xx l-1">
            <div className="c-findSpecialist__contactSection-form">
              <ClientForm {...formProps} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FindSpecialistContactSection
