import React from 'react'

type FindJobSelectionHeadProps = {
  tilesHeader: string,
  tilesText: string,
}

const FindJobSelectionHead = ({ tilesHeader, tilesText }: FindJobSelectionHeadProps) => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-8 col-xl-5 col-xxl-4">
        <div className="c-findJobSelection__top text-center">
          <h2 className="c-findJobSelection__headline secondary-heading">
            {tilesHeader}
          </h2>
          <p className="c-findJobSelection__subheadline">
            {tilesText}
          </p>
        </div>
      </div>
    </div>
  )
}

export default FindJobSelectionHead
