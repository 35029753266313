import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import WhitepaperForm, { WhitepaperFormProps } from "../../zohoForms/WhitepaperForm"
import { StoryblokImageType } from "../../types"
import { cutInLines } from "../contentEdit"

type WhitepaperHeroProps = {
  whitepaperTitle: string;
  leadParagraph: string;
  reusableForm: { content: WhitepaperFormProps };
  headerImage: StoryblokImageType;
  docPath: string
  docName: string
}

const WhitepaperHero = ({
                          leadParagraph,
                          reusableForm,
                          whitepaperTitle,
                          headerImage,
                          docName,
                          docPath
                        }: WhitepaperHeroProps) => {

  return (
    <div className="c-whitepaperHero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 c-whitepaperHero__content col-iconity-36">
            <h1 className="c-whitepaperHero__headline">
              {cutInLines(whitepaperTitle)}
            </h1>
            <p className="c-whitepaperHero__text">
              {leadParagraph}
            </p>
          </div>
          <div className="col-12 col-xl-6 col-xxl-4 offset-xxl-1 c-whitepaperHero__formWrapper">
            {reusableForm.content && <WhitepaperForm
              {...reusableForm.content}
              formSource={whitepaperTitle}
              docName={docName}
              docPath={docPath}
            />}
          </div>
        </div>
      </div>
      <div className="c-whitepaperHero__imageContainer">
        <div className="container ">
          <div className="row">
            <div className="col-12 col-xl-6 col-xxl-5 offset-xxl-1 col-iconity-36">
              <StoryblokImage image={headerImage} className="c-whitepaperHero__image"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhitepaperHero
