import React from "react"

import WebinarForm, { WebinarFormProps } from "../../zohoForms/WebinarForm"
import { checkLinkUrl } from "../contentEdit"
import { ctaTarget } from "../../types"

type WebinarMobileFormProps = {
  formSource: string
  thankYouPage: ctaTarget
  reusableForm: WebinarFormProps
}

const WebinarMobileForm = ({
  formSource,
  thankYouPage,
  reusableForm,
}: WebinarMobileFormProps) => {
  return (
    <section className="c-webinarMobileForm">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="c-webinarMobileForm__content">
              <WebinarForm
                {...reusableForm}
                formSource={formSource}
                successRedirectUrl={checkLinkUrl(thankYouPage.cached_url)}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WebinarMobileForm
