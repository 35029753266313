import React from 'react'

import { cutInLines } from '../contentEdit'
import { DescriptionType } from '../../types'

import StoryblokImage from '../shared/StoryblokImage'

type IntroductionProps = {
  content: DescriptionType,
}

const Introduction = ({ content }: IntroductionProps) => {
  return (
    <section className="c-introduction" id="scrollgoal">
      <div className="container">
        <div className="row">
          <div className="col-0 col-xl-1 col-xxl-2" />
          <div className="col-lg-6 col-xl-5 col-xxl-4">
            <div className="c-introduction__content">
              <h2 className="c-introduction__headline">
                {cutInLines(content.title)}
              </h2>
              <p>
                {content.text}
              </p>
            </div>
          </div>
        </div>
      </div>

      <StoryblokImage className="c-introduction__image" image={content.image} />
    </section>
  )
}

export default Introduction
