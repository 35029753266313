import React from "react"
import Layout from "../components/layout/Layout"
import { SeoMetaType, WhitepaperType } from "../types"
import WhitepaperHero from "../components/whitepaper/WhitepaperHero"
import WhitepaperContent from "../components/whitepaper/WhitepaperContent"
import Testimonials from "../components/shared/Testimonials"

type WhitepaperDetailProps = {
  seoMeta: SeoMetaType;
  pathname: string;
  origin: string;
} & WhitepaperType;

const WhitepaperDetail = ({
                            seoMeta,
                            pathname,
                            origin,
                            content,
                            contentHeader,
                            contentImage,
                            headerImage,
                            headerImageBigThumb,
                            headerImageThumb,
                            leadParagraph,
                            testimonial,
                            testimonialHeader,
                            whitepaperTitle,
                            reusableForm,
                            docPath,
                            docName
                          }: WhitepaperDetailProps) => {

  return (
    <Layout pathname={pathname} origin={origin} contentClass="whitepaperPage" seoMeta={seoMeta}>
      <WhitepaperHero
        whitepaperTitle={whitepaperTitle}
        leadParagraph={leadParagraph}
        headerImage={headerImage}
        reusableForm={reusableForm}
        docPath={docPath?.cached_url}
        docName={docName}
      />
      <WhitepaperContent
        content={content}
        contentHeader={contentHeader}
        contentImage={contentImage}
      />
      <Testimonials
        isTestimonial
        headline={testimonialHeader}
        testimonials={testimonial}
      />
    </Layout>
  )
}

export default WhitepaperDetail
