import React from 'react'
import { CtaBanner } from '../../types'

type CrossroadsProps = {
  ctaBanner: CtaBanner,
}

const Crossroads = ({ ctaBanner }: CrossroadsProps) => {
  return (
    <section className="c-crossroads">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8 col-xxl-6">
            <div className="c-crossroads__box d-flex flex-column align-items-center text-center">
              <h3>
                {ctaBanner.title}
              </h3>
              <p>
                {ctaBanner.text}
              </p>
              {(Boolean(ctaBanner.ctaButton1) || Boolean(ctaBanner.ctaButton2)) && (
                <div className="c-crossroads__link d-flex flex-column flex-md-row-reverse">
                  {ctaBanner.ctaButton1 && ctaBanner.cta1Target.cached_url && (
                    <a href={ctaBanner.cta1Target.cached_url} className="btnPrimary btnPrimary--fullWidth btn--hoverMove btnPrimary--inBubble">
                      {ctaBanner.ctaButton1}
                    </a>
                  )}

                  {ctaBanner.ctaButton2 && ctaBanner.cta2Target.cached_url && (
                    <a href={ctaBanner.cta2Target.cached_url} className="btnPrimary btnPrimary--fullWidth btn--hoverMove btnPrimary--inBubble btnPrimary--black">
                      {ctaBanner.ctaButton2}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Crossroads
