import React from 'react'

import TeamCard from '../shared/TeamCard'
import StoryblokImage from '../shared/StoryblokImage'

import { PersonalIntroType, StoryblokImageType } from "../../types"

type ContactUsHeroProps = {
  title: string,
  intros: Array<PersonalIntroType>,
  image: StoryblokImageType,
}

const ContactUsHero = ({ title, intros, image }: ContactUsHeroProps) => {
  return (
    <div className="c-contactUsHero">
      <StoryblokImage image={image} className="c-contactUsHero__bg" />

      <div className="container">
        <div className="row">
          <div className="col-md-1" />

          <div className="col-md-11 col-lg-9 col-xl-7 col-xxl-6 col-xxxl-5">
            <h1 className="c-contactUsHero__headline">
              {title}
            </h1>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-5 mb-5 mb-xl-0">
            <div className="c-contactUsHero__item--moveLeft h-100">
              <TeamCard content={intros[0]?.content} />
            </div>
          </div>

          <div className="col-lg-10 col-xl-5">
            <div className="c-contactUsHero__item--moveRight h-100">
              <TeamCard content={intros[1]?.content} />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default ContactUsHero
