import React from 'react'
import classNames from 'classnames'

type FindJobSelectionTitlesProps = {
  isAppSelected: boolean,
  handleSwitchClick: () => void,
  titleLeft: string,
  titleRight: string,
}

const FindJobSelectionTitles = ({ isAppSelected , handleSwitchClick, titleLeft, titleRight }: FindJobSelectionTitlesProps) => {
  const renderHeadClassNames = () => (
    classNames({
      'c-findJobSelection__head': true,
      'c-findJobSelection__head--data': !isAppSelected,
    })
  )

  const renderHeadItemClassNames = isApp => (
    classNames({
      'c-findJobSelection__headItem': true,
      'c-findJobSelection__headItem--selected': isApp === isAppSelected,
    })
  )

  return (
    <div className={renderHeadClassNames()}>
      <div className={renderHeadItemClassNames(false)} onClick={() => handleSwitchClick()}>
        {titleLeft}
      </div>
      <div className={renderHeadItemClassNames(true)} onClick={() => handleSwitchClick()}>
        {titleRight}
      </div>
    </div>
  )
}

export default FindJobSelectionTitles
