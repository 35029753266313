import React from "react"

import Layout from '../components/layout/Layout'
import ProcessHero from '../components/shared/ProcessHero'
import Steps from '../components/shared/Steps'
import Testimonials from '../components/shared/Testimonials'
import Crossroads from '../components/shared/Crossroads'

import { HeaderWithImageType, SeoMetaType, StepsBlockType, CtaBanner, TestimonialType } from '../types'

type OurProcessPageProps = {
  pathname: string,
  origin: string,
  seoMeta: SeoMetaType,
  header: Array<HeaderWithImageType>,
  stepsBlock: Array<StepsBlockType>,
  ctaBanner: Array<CtaBanner>,
  testimonialTitle: string,
  clientTestimonial: Array<TestimonialType>,
}

function OurProcessPage({ pathname, origin, seoMeta, header, stepsBlock, ctaBanner, testimonialTitle, clientTestimonial }: OurProcessPageProps) {
  return (
    <Layout pathname={pathname} origin={origin} headerFix lightGreyBg contentClass="processPage" seoMeta={seoMeta} >
      <ProcessHero header={header[0]} />
      <Steps stepsBlock={stepsBlock} />
      <Crossroads ctaBanner={ctaBanner[0]} />
      <Testimonials isTestimonial headline={testimonialTitle} testimonials={clientTestimonial} />
    </Layout>
  )
}

export default OurProcessPage
