import React from "react"

import DetailDescription from "./DetailDescription"
import DetailPatrons from "./DetailPatrons"

import {
  RecruiterCardContentType,
  ExpertiseGuarantorContentType,
} from "../../types"

const FOOTNOTE = '*Thank you for taking the time to read through this job description. We do our best to try and provide feedback to all applicants, but sometimes we are unable to contact applicants whose qualifications do not match the essential requirements, such as visa status or seniority. If you do not hear from us within two weeks, your application was not selected for the next round.';

type DetailTextProps = {
  section1Title: string
  section1Text: any
  section2Title: string
  section2Text: any
  section3Title: string
  section3Text: any
  section4Title: string
  section4Text: any
  recruiterCard: RecruiterCardContentType
  expertiseGuarantor: ExpertiseGuarantorContentType
}

const DetailText = ({
  section1Title,
  section1Text,
  section2Title,
  section2Text,
  section3Title,
  section3Text,
  section4Title,
  section4Text,
  recruiterCard,
  expertiseGuarantor,
}: DetailTextProps) => {
  return (
    <section className="pb-0 pb-md-4 pb-lg-5">
      <div className="container pb-5">
        <div className="row justify-content-center pb-5">
          <div className="col-xl-7 col-xxl-6">
            <DetailDescription
              section1Title={section1Title}
              section1Text={section1Text}
              section2Title={section2Title}
              section2Text={section2Text}
              section3Title={section3Title}
              section3Text={section3Text}
              section4Title={section4Title}
              section4Text={section4Text}
            />
            <small><i>{FOOTNOTE}</i></small>
          </div>
          <div className="col-xl-1" />
          <div className="col-xl-4 col-xxl-3">
            <DetailPatrons
              recruiterCard={recruiterCard}
              expertiseGuarantor={expertiseGuarantor}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default DetailText
