import React from "react"
import Layout from "../components/layout/Layout"
import {
  BlogCardType,
  CtaBanner,
  ctaTarget,
  HeaderWithImageType,
  ResourceCardType,
  SeoMetaType,
} from "../types"
import ResourceCardsContainer from "../components/shared/ResourceCardsContainer"
import ResourcesCentreHero from "../components/resourcesCentre/ResourcesCentreHero"
import Crossroads from "../components/shared/Crossroads"

type ResourcesCentreProps = {
  seoMeta: SeoMetaType
  pathname: string
  origin: string

  header: HeaderWithImageType[]

  blogTitle: string
  blogCards: ResourceCardType[]
  blogCta: string
  blogCtaLink: ctaTarget
  blogCardButton: string
  blogCardTag: string

  webinarTitle: string
  webinarCards: ResourceCardType[]
  webinarCta: string
  webinarCtaLink: ctaTarget
  webinarCardButton: string
  webinarCardTag: string

  whitepaperTitle: string
  whitepaperCards: ResourceCardType[]
  whitepaperCta: string
  whitepaperCtaLink: ctaTarget
  whitepaperCardButton: string
  whitepaperCardTag: string

  onDemandTitle: string
  onDemandCards: ResourceCardType[]
  onDemandCta: string
  onDemandCtaLink: ctaTarget
  onDemandCardButton: string
  onDemandCardTag: string

  reusableCtaBanner: { content: CtaBanner }
}

const ResourcesCentre = ({
  seoMeta,
  pathname,
  origin,
  header,
  blogCards,
  blogCta,
  blogCtaLink,
  blogTitle,
  blogCardButton,
  blogCardTag,
  webinarCardButton,
  webinarCards,
  webinarCardTag,
  webinarCta,
  webinarCtaLink,
  webinarTitle,
  whitepaperCardButton,
  whitepaperCards,
  whitepaperCardTag,
  whitepaperCta,
  whitepaperCtaLink,
  whitepaperTitle,
  reusableCtaBanner,
  onDemandTitle,
  onDemandCards,
  onDemandCta,
  onDemandCtaLink,
  onDemandCardButton,
  onDemandCardTag,
}: ResourcesCentreProps) => {
  return (
    <Layout
      pathname={pathname}
      origin={origin}
      contentClass="webinarPage"
      seoMeta={seoMeta}
    >
      {header[0] && (
        <ResourcesCentreHero
          image={header[0].image}
          title={header[0].title}
          text={header[0].text}
        />
      )}
      {blogCards.length > 0 && (
        <ResourceCardsContainer
          title={blogTitle}
          cards={blogCards}
          cardButton={blogCardButton}
          cardTag={blogCardTag}
          cta={blogCta}
          ctaLink={blogCtaLink}
          background={"topRight"}
          topCards
        />
      )}
      {whitepaperCards.length > 0 && (
        <ResourceCardsContainer
          title={whitepaperTitle}
          cards={whitepaperCards}
          cardButton={whitepaperCardButton}
          cardTag={whitepaperCardTag}
          cta={whitepaperCta}
          ctaLink={whitepaperCtaLink}
          background={"topLeft"}
          topCards
        />
      )}
      {webinarCards.length > 0 && (
        <ResourceCardsContainer
          title={webinarTitle}
          cards={webinarCards}
          cardButton={webinarCardButton}
          cardTag={webinarCardTag}
          cta={webinarCta}
          ctaLink={webinarCtaLink}
          background={"fullWidth"}
          topCards
        />
      )}
      {onDemandCards?.length > 0 && (
        <ResourceCardsContainer
          title={onDemandTitle}
          cards={onDemandCards}
          cardButton={onDemandCardButton}
          cardTag={onDemandCardTag}
          cta={onDemandCta}
          ctaLink={onDemandCtaLink}
          background={"topLeft"}
          topCards
        />
      )}
      {reusableCtaBanner.content && (
        <Crossroads ctaBanner={reusableCtaBanner.content} />
      )}
    </Layout>
  )
}

export default ResourcesCentre
