import React, { useEffect } from "react"
import { useZohoFormLogic, ZohoFormFields } from "./zohoLogic"
import TextField from "./formComponents/TextField"
import CheckBox from "./formComponents/CheckBox"
import { CommonFormProps } from "../types"
import TextArea from "./formComponents/TextArea"
import SubmitButton from "./formComponents/SubmitButton"

const ZOHO_CLIENT_SUBMIT_URL =
  "https://forms.zohopublic.com/sarapodmolikova/form/Clients/formperma/6J-Aitcsijq0KDuCliUy2d8AXY_3Fz0K1ILKdC_8x7Q/htmlRecords/submit"

type ClientFormFields = {
  firstName: string
  lastName: string
  email: string
  company: string
  description: string
  newsletter: boolean
  marketing: boolean
  clientOrCandidate: string
}

const zohoClientFormFields: ZohoFormFields<ClientFormFields> = {
  firstName: {
    zohoFieldName: "SingleLine7",
    initialValue: "",
    required: true,
  },
  lastName: {
    zohoFieldName: "SingleLine8",
    initialValue: "",
    required: true,
  },
  email: {
    zohoFieldName: "Email",
    initialValue: "",
    required: true,
    runEmailValidation: true,
  },
  company: {
    zohoFieldName: "SingleLine9",
    initialValue: "",
    required: false,
  },
  description: {
    zohoFieldName: "MultiLine",
    initialValue: "",
    required: false,
  },
  newsletter: {
    zohoFieldName: "DecisionBox",
    initialValue: false,
    required: false,
  },
  marketing: {
    zohoFieldName: "DecisionBox1",
    initialValue: false,
    required: false,
  },
  clientOrCandidate: {
    zohoFieldName: "SingleLine10",
    initialValue: "Client",
  },
}

export type ClientFormProps = {
  companyLabel: string
  descriptionLabel: string
  prefilledDescription?: string
} & CommonFormProps<ClientFormFields>

const ClientForm = ({
  titleForm,
  submitLabel,
  formSource,
  successRedirectUrl,
  requiredFieldErrorMessage,
  validEmailErrorMessage,
  submitErrorMessage,
  hideNewsletter,
  prefilledDescription,
  ...labels
}: ClientFormProps) => {
  const {
    values,
    errors,
    isFetching,
    submitError,
    setValue,
    clearError,
    doSubmit,
  } = useZohoFormLogic<ClientFormFields>({
    formIdentifier: formSource,
    zohoSubmitUrl: ZOHO_CLIENT_SUBMIT_URL,
    successRedirectUrl: successRedirectUrl,
    requiredFieldValidationMessage: requiredFieldErrorMessage,
    validEmailValidationMessage: validEmailErrorMessage,
    submitErrorMessage: submitErrorMessage,
    zohoFormFields: zohoClientFormFields,
  })

  useEffect(() => {
    if (prefilledDescription) {
      setValue("description", prefilledDescription)
    }
  }, [prefilledDescription])

  return (
    <div className="form__container">
      {titleForm && <h3 className="form__headline">{titleForm}</h3>}
      <div className="form__row">
        <TextField
          name="firstName"
          label={labels.firstNameLabel}
          value={values.firstName}
          setValue={setValue}
          error={errors.firstName}
          clearError={clearError}
        />
        <TextField
          name="lastName"
          label={labels.lastNameLabel}
          value={values.lastName}
          setValue={setValue}
          error={errors.lastName}
          clearError={clearError}
        />
      </div>
      <div className="form__row">
        <TextField
          name="email"
          label={labels.emailLabel}
          value={values.email}
          setValue={setValue}
          error={errors.email}
          clearError={clearError}
        />
        <TextField
          name="company"
          label={labels.companyLabel}
          value={values.company}
          setValue={setValue}
          error={errors.company}
          clearError={clearError}
        />
      </div>
      <div className="form__row">
        <TextArea
          name="description"
          label={labels.descriptionLabel}
          value={values.description}
          setValue={setValue}
          error={errors.description}
          clearError={clearError}
        />
      </div>

      <div className="form__checkBoxContainer">
        <CheckBox
          name="marketing"
          label={labels.marketingLabel}
          value={values.marketing}
          setValue={setValue}
        />
      </div>
      <SubmitButton
        isFetching={isFetching}
        doSubmit={doSubmit}
        submitLabel={submitLabel}
        submitError={submitError}
      />
    </div>
  )
}

export default ClientForm
