import React from "react"
import { NODE_PARAGRAPH, render } from "storyblok-rich-text-react-renderer"

export const cutInLines = (string: string) => {
  const array = string?.split("<br />")

  const linedText = array?.map((str, i) => (
    <React.Fragment key={i}>
      {str}
      {array[i + 1] ? <br className="break" /> : ""}
    </React.Fragment>
  ))

  return linedText
}

export const checkLinkUrl = link => {
  if (!link)
    return "/"

  if (link === "home")
    return "/"

  const lastCharacter = link[link.length - 1]
  if (lastCharacter !== "/") {
    link = link + "/";
  }

  const firstCharacter = link[0]
  if (firstCharacter !== "/") {
    link = "/" + link
  }

  return link;
}

export const renderFilteredRichText = richText => {
  const title =
    richText &&
    render(richText, {
      nodeResolvers: {
        [NODE_PARAGRAPH]: children => {
          return children ? <p>{children}</p> : null
        },
      },
    })

  return title
}
