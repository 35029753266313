import * as React from "react"
import { StoryblokImageType } from "../../types"
import { checkLinkUrl, cutInLines } from "../contentEdit"

export type ResourceCardProps = {
  image?: StoryblokImageType
  tag?: string
  title: string
  date?: string
  ctaButton?: string
  link?: string
  cardCount: number
}

const ResourceCard = ({
  image,
  ctaButton,
  link,
  date,
  tag,
  title,
  cardCount,
}: ResourceCardProps) => {
  return (
    <a href={checkLinkUrl(link)} className="c-resourceCard">
      {image && (
        <div
          className="c-resourceCard__image"
          style={{ backgroundImage: `url(${image.filename})` }}
        />
      )}
      <div className="c-resourceCard__textContainer">
        {tag && <span className="c-resourceCard__tag">{tag}</span>}
        {title && (
          <span className="c-resourceCard__headline">{cutInLines(title)}</span>
        )}
        {date && <span className="c-resourceCard__date">{date}</span>}
        {ctaButton && (
          <span className="c-resourceCard__callToAction hoverUnderlineAnimation">
            {ctaButton}
          </span>
        )}
      </div>
    </a>
  )
}

export default ResourceCard
