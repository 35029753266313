import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import PositionItem from "../openPositions/PositionItem"

import { JobDescriptionNodeType } from "../../types"

type CompanyListingsProps = {
  title: string
  text: any
  hotPositions: JobDescriptionNodeType[]
  positions: JobDescriptionNodeType[]
}

const CompanyListings = ({
  title,
  text,
  hotPositions,
  positions,
}: CompanyListingsProps) => {
  const description = render(text)

  const hotJobs = hotPositions?.map((job, i) => (
    <PositionItem key={`simplity-hot-job-${i}`} hot job={job} />
  ))

  const normalJobs = positions.map((job, i) => (
    <PositionItem key={`simplity-normal-job-${i}`} job={job} />
  ))

  return (
    <section className="c-companyListings">
      <div className="container">
        <div className="row">
          <div className="col-xxl-1 col-xxxl-2" />

          <div className="col-xl-8 col-xxl-5">
            <div className="c-companyListings__content">
              <h2 className="c-companyListings__title">{title}</h2>
              <div className="c-companyListings__description">
                {description}
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-xxl-10 col-xxxl-8">
            <div className="c-companyListings__jobs">
              {hotJobs}
              {normalJobs}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyListings
