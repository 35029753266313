import * as React from "react"
import SubmenuHeader from "./SubmenuHeader"
import SubmenuDivider from "./SubmenuDivider"
import SubmenuButton from "./SubmenuButton"
import SubmenuSimpleItem from "./SubmenuSimpleItem"

const SubmenuComponents = type => {
  const ComponentList = {
    'TopNav Submenu Header': SubmenuHeader,
    'TopNav Submenu Divider': SubmenuDivider,
    'TopNav Submenu Item': SubmenuSimpleItem,
    'TopNav Submenu Button': SubmenuButton,
  }

  if (typeof ComponentList[type] === "undefined") {
    console.log(`Component "${type}" does not exist`)
    return 'span'
  }
  return ComponentList[type]
}

export default SubmenuComponents
