import React from 'react'

import StoryblokImage from '../shared/StoryblokImage'

import { StoryblokImageType } from '../../types'

export type StepItemProps = {
  order: number,
  headline: string,
  image: StoryblokImageType,
  text: string,
}

const StepItem = ({ order, headline, image, text }: StepItemProps) => {
  return (
    <div className="row">
      <div className="col-lg-1 col-xl-2 col-xxl-3" />

      <div className="col-lg-11 col-xl-10 col-xxl-8">
        <div className="c-stepItem d-flex flex-column">
          <span className="c-stepItem__no">
            {order}
          </span>

          <div className="c-stepItem__row d-flex flex-column flex-md-row align-items-start">
            <StoryblokImage image={image} className="c-stepItem__image" />

            <div className="c-stepItem__content">
              <h3 className="c-stepItem__headline">{headline}</h3>
              <div className="c-stepItem__description">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepItem
