import React from 'react'

import TeamCard from '../shared/TeamCard'

import cactus from '../../assets/images/team-cactus.png'

import { PersonalIntroType } from '../../types'

type MeetTeamMembersProps = {
  manager: Array<PersonalIntroType>,
  team: Array<PersonalIntroType>,
}

const MeetTeamMembers = ({ manager, team }: MeetTeamMembersProps) => {
  const teamMembers = team?.map((member, i) => (
    <div className="col-lg-10 col-xl-6 col-xxl-5 mb-5" key={`team-member-${i}`}>
      <TeamCard content={member.content} />
    </div>
  ))

  return (
    <div className="c-meetTeamMembers">
      <img src={cactus} className="c-meetTeamMembers__bg"/>

      <div className="container">
        <div className="c-meetTeamMembers__head">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-6 col-xxl-5">
              {manager[0] && (
                <TeamCard content={manager[0]?.content} />
              )}
            </div>
            <div className="col-xl-6 col-xxl-5" />
          </div>
        </div>

        <div className="c-meetTeamMembers__members">
          <div className="row justify-content-center">
            {teamMembers}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MeetTeamMembers
