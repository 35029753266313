import React from "react"
import { ctaTarget, StoryblokImageType } from "../../types"

type PositionsConnectionProps = {
  title: string
  description: string
  ctaButton: string
  ctaTarget: ctaTarget
  background: StoryblokImageType
}

const PositionsConnection = ({
  title,
  description,
  ctaButton,
  ctaTarget,
  background,
}: PositionsConnectionProps) => {
  return (
    <div
      className="c-positionsConnection"
      style={{ backgroundImage: `url(${background?.filename})` }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-xxl-6">
            <div className="c-positionsConnection__content">
              <h2 className="c-positionsConnection__headline">{title}</h2>

              <p className="c-positionsConnection__text">{description}</p>

              <a
                href={ctaTarget.cached_url}
                className="btnPrimary btn--hoverMove"
              >
                {ctaButton}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PositionsConnection
