import React, { useState } from "react"

import Layout from "../components/layout/Layout"
import OnDemandWebinarHero from "../components/onDemandWebinar/OnDemandWebinarHero"
import OnDemandInfo from "../components/onDemandWebinar/OnDemandInfo"
import YoutubeOverlay from "../components/onDemandWebinar/YoutubeOverlay"

import { NewsletterSignupFormProps } from "../zohoForms/NewsletterSignupForm"

import {
  SeoMetaType,
  ctaTarget,
  StoryblokImageType,
  BlogAuthorTypeContent,
} from "../types"

type OnDemandWebinarProps = {
  pathname: string
  origin: string
  seoMeta: SeoMetaType
  youtubeEmbedLink: ctaTarget
  title: string
  youtubeImage: StoryblokImageType
  watchTime: string
  presenters: BlogAuthorTypeContent[]
  detailTitle: string
  detailText: any
  reusableForm: { content: NewsletterSignupFormProps }
  thankYouPage: ctaTarget
}

const OnDemandWebinar = ({
  pathname,
  origin,
  seoMeta,
  youtubeEmbedLink,
  title,
  youtubeImage,
  watchTime,
  presenters,
  detailTitle,
  detailText,
  reusableForm,
  thankYouPage,
}: OnDemandWebinarProps) => {
  const [isYoutubeOpened, setYoutubeOpened] = useState(false)

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      headerFix
      contentClass="onDemandWebinarPage"
      seoMeta={seoMeta}
      hasOverlay
    >
      <OnDemandWebinarHero
        openYoutubeOverlay={() => setYoutubeOpened(true)}
        title={title}
        youtubeImage={youtubeImage}
        watchTime={watchTime}
        presenters={presenters}
      />
      <OnDemandInfo
        title={detailTitle}
        text={detailText}
        reusableForm={reusableForm?.content}
        thankYouPage={thankYouPage}
      />
      <YoutubeOverlay
        link={youtubeEmbedLink.url}
        isOpen={isYoutubeOpened}
        closeYoutubeOverlay={() => setYoutubeOpened(false)}
      />
    </Layout>
  )
}

export default OnDemandWebinar
