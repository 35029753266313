import React from "react"

import CandidateForm, {
  CandidateFormProps,
} from "../../zohoForms/CandidateForm"

type DetailFormSectionType = {
  formTitle: string
  textForm: string
  form: CandidateFormProps
  formSource: string
}

const DetailFormSection = ({
  formTitle,
  textForm,
  form,
  formSource,
}: DetailFormSectionType) => {
  return (
    <div className="c-detailFormSection">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-7 col-xl-5 col-xxl-4">
            <h2 className="c-detailFormSection__headline secondary-heading text-center">
              {formTitle}
            </h2>
            <p className="c-detailFormSection__text text-center">{textForm}</p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-9 col-lg-7 col-xl-5 col-xxl-4">
            <div className="c-detailForm">
              {form && <CandidateForm {...form} formSource={formSource} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailFormSection
