import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import footerBackground from '../../assets/images/footer-background.png'

import FooterTop from './FooterTop'
import FooterColumns from './FooterColumns'
import FooterSocials from './FooterSocials'
import FooterBottom from './FooterBottom'

export type FooterProps = {
  lightGreyBg?: boolean,
  activeLanguage: string,
}

const useFooterDataFromStoryblok = (lang: string = 'default') => {
  const queryResult = useStaticQuery(
    graphql`
    query {
      allStoryblokEntry(filter: {name: {eq: "footer"}}) {
        edges {
          node {
            content
            lang
          }
        }
      }
    }
  `
  );

  const edgeForLang = queryResult.allStoryblokEntry.edges
    .find(edge => edge.node.lang === lang);

  return edgeForLang ? JSON.parse(edgeForLang.node.content) : undefined;
};

const Footer = ({ lightGreyBg, activeLanguage }: FooterProps) => {
  const content = useFooterDataFromStoryblok(activeLanguage);

  return (
    <footer className="footer" style={{ backgroundImage: `url(${footerBackground})` }}>
      {lightGreyBg && (
        <div className="footer--bgGrey" />
      )}

      <div className="container">
        <FooterTop slogan={content?.slogan} logo={content?.logo} />
        <FooterColumns boxes={content?.linkBoxes} />
        <FooterSocials facebook={content?.facebookLink} linkedin={content?.linkedinLink} instagram={content?.instagramLink} />
        <FooterBottom copyright={content?.copyright} bottomLinks={content?.bottomLinks} />
      </div>
    </footer>
  )
}

export default Footer
