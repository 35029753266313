import React, { useState } from "react"
import Layout from "../components/layout/Layout"
import scrollTo from "gatsby-plugin-smoothscroll"
import {
  CandidateListingType,
  FiftyFiftyBlockType,
  FindJobCards,
  FindJobLogos,
  HeaderWithImageType,
  PersonalIntroContentType,
  SeoMetaType,
  StoryblokImageType,
  TestimonialType,
  ThreeElementBlockType,
} from "../types"
import ThreeElementBlockContainer from "../components/shared/ThreeElementBlockContainer"
import LogosList from "../components/shared/LogosList"
import FindJobSelection from "../components/findJob/FindJobSelection"
import FindJobSelectionStaticBoxes from "../components/findJob/FindJobSelectionStaticBoxes"
import CandidateListing from "../components/shared/CandidateListing"
import FiftyFiftyBlock from "../components/shared/FiftyFiftyBlock"
import Testimonials from "../components/shared/Testimonials"
import FindSpecialistContactSection from "../components/findSpecialist/ContactSection"
import { ClientFormProps } from "../zohoForms/ClientForm"
import FindSpecialistHero from "../components/findSpecialist/FindSpecialistHero"

type FindJobPageProps = {
  pathname: string
  origin: string
  seoMeta: SeoMetaType

  header: HeaderWithImageType[]

  benefitsHeader: string
  benefitsBlocks: ThreeElementBlockType[]

  clientsLogosHeader: string
  clientsLogos: StoryblokImageType[]

  tilesHeader: string
  tilesText: string
  titleLeft: string
  titleRight: string
  logosLeft: FindJobLogos[]
  logosRight: FindJobLogos[]
  belowLogoText: string
  cards: FindJobCards[]

  candidateListingHeader: string
  candidateListing: CandidateListingType[]

  servicesHeader: string
  servicesBlocks: ThreeElementBlockType[]

  recruitmentProcess: FiftyFiftyBlockType[]

  testimonial: TestimonialType[]
  testimonialHeader: string

  recruiterCard: { content: PersonalIntroContentType }[]
  defaultDescriptionText: string
} & ClientFormProps

function FindJobPage({
  header,
  origin,
  pathname,
  seoMeta,
  benefitsHeader,
  benefitsBlocks,
  clientsLogos,
  clientsLogosHeader,
  tilesHeader,
  tilesText,
  titleLeft,
  titleRight,
  logosLeft,
  logosRight,
  belowLogoText,
  cards,
  candidateListing,
  candidateListingHeader,
  servicesBlocks,
  servicesHeader,
  recruitmentProcess,
  testimonial,
  testimonialHeader,
  recruiterCard = [],
  defaultDescriptionText,
  ...formProps
}: FindJobPageProps) {
  const [contactFormDescription, setContactFormDescription] = useState<string>()

  const scrollToContactForm = (candidate: CandidateListingType) => {
    setContactFormDescription(
      defaultDescriptionText.replace('JOB_TITLE', candidate.jobTitle)
    )
    scrollTo(".form__container")
  }

  return (
    <Layout
      pathname={pathname}
      origin={origin}
      seoMeta={seoMeta}
      lightGreyBg={true}
      headerFix
      contentClass="findSpecialistPage"
    >
      <FindSpecialistHero header={header[0]} />
      <ThreeElementBlockContainer
        blocks={benefitsBlocks}
        title={benefitsHeader}
        className={"findSpecialistPage__benefits"}
      />
      <LogosList title={clientsLogosHeader} logos={clientsLogos} />
      <FindJobSelection
        tilesHeader={tilesHeader}
        tilesText={tilesText}
        titleLeft={titleLeft}
        titleRight={titleRight}
        logosLeft={logosLeft}
        logosRight={logosRight}
        belowLogoText={belowLogoText}
        cards={cards}
        BoxesComponent={FindJobSelectionStaticBoxes}
      />
      <CandidateListing
        title={candidateListingHeader}
        candidateListing={candidateListing}
        onClick={scrollToContactForm}
      />
      <ThreeElementBlockContainer
        blocks={servicesBlocks}
        title={servicesHeader}
        className={"findSpecialistPage__services"}
      />
      {recruitmentProcess && recruitmentProcess[0] && (
        <FiftyFiftyBlock {...recruitmentProcess[0]} />
      )}
      <Testimonials
        headline={testimonialHeader}
        testimonials={testimonial}
        isTestimonial
      />
      <FindSpecialistContactSection
        recruiters={recruiterCard.map(q => q.content)}
        prefilledDescription={contactFormDescription}
        {...formProps}
      />
    </Layout>
  )
}

export default FindJobPage
