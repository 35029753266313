import React from "react"

import StoryblokImage from "../shared/StoryblokImage"

import { ctaTarget, StoryblokImageType } from "../../types"

type ThankYouContentProps = {
  title: string,
  text: string,
  image: StoryblokImageType,
  ctaButtonLabel: string,
  ctaButtonLink: ctaTarget,
}

const ThankYouContent = ({ title, text, image, ctaButtonLabel, ctaButtonLink }: ThankYouContentProps) => {
  return (
    <div className="c-thankYou">
      <StoryblokImage image={image} className="c-thankYou__bg" />

      <div className="container">
        <div className="row">
          <div className="col-xl-2" />

          <div className="col-md-6 col-xl-4 col-xxl-3">
            <div className="c-thankYou__content">
              <h1 className="c-thankYou__headline">
                {title}
              </h1>
              <p className="c-thankYou__text">
                {text}
              </p>
              {ctaButtonLabel && ctaButtonLink?.cached_url && <a
                href={ctaButtonLink.cached_url}
                className="c-thankYou__ctaButton btnSecondary"
              >{ctaButtonLabel}</a>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYouContent
