import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { checkLinkUrl } from "../contentEdit"

import NewsletterSignupForm, {
  NewsletterSignupFormProps,
} from "../../zohoForms/NewsletterSignupForm"
import { ctaTarget } from "../../types"

type OnDemandInfoProps = {
  title: string
  text: any
  reusableForm: NewsletterSignupFormProps
  thankYouPage: ctaTarget
}

const OnDemandInfo = ({
  title,
  text,
  reusableForm,
  thankYouPage,
}: OnDemandInfoProps) => {
  const description = render(text)

  return (
    <section className="c-onDemandInfo">
      <div className="container">
        <div className="row justify-content-center flex-column flex-xl-row align-items-center align-items-xl-start">
          <div className="col-lg-10 col-xl-5">
            <div className="c-onDemandInfo__content">
              <h2 className="c-onDemandInfo__title">{title}</h2>
              <div className="c-onDemandInfo__text">{description}</div>
            </div>
          </div>

          <div className="d-none d-xl-block col-xl-1"></div>

          <div className="col-lg-8 col-xl-6 col-xxl-4">
            <div className="c-onDemandInfo__form">
              {reusableForm && (
                <NewsletterSignupForm
                  {...reusableForm}
                  successRedirectUrl={checkLinkUrl(thankYouPage.cached_url)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OnDemandInfo
