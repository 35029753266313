import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { BlogAuthorType, StoryblokImageType } from "../../types"
import { checkLinkUrl, cutInLines } from "../contentEdit"

type BlogListingHeroProps = {
  tag?: string;
  title: string;
  leadParagraph?: string;
  authors?: { content: BlogAuthorType }[];
  ctaButton: string;
  ctaButtonLink: string;
  image: StoryblokImageType;
}

const BlogListingHero = ({
                           image,
                           title,
                           authors,
                           ctaButton,
                           ctaButtonLink,
                           leadParagraph,
                           tag
                         }: BlogListingHeroProps) => {

  return (
    <div className="c-blogListingHero">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 col-xxl-5 offset-xxl-1 c-blogListingHero__content col-iconity-36">
            {tag && <div className="c-blogListingHero__tag">
              {tag}
            </div>}
            <h1 className="c-blogListingHero__headline">
              {cutInLines(title)}
            </h1>
            <div className="c-blogListingHero__leadText">
              {leadParagraph}
            </div>
            {authors.length > 0 && <div className="c-blogListingHero__author">
              <div className="c-blogListingHero__authorPhotosWrapper">
                {authors
                  .map(author => author.content)
                  .filter(content => !!content)
                  .map(author => (
                    <StoryblokImage
                      image={author.profilePhoto}
                      className={"c-blogListingHero__authorPhoto"}
                      key={author.name}
                    />
                  ))}
              </div>
              <div className="c-blogListingHero__authorName">
                {authors
                  .map(author => author.content?.name)
                  .filter(name => !!name)
                  .join(", ")}
              </div>
            </div>}
            {ctaButtonLink && ctaButton && <a href={checkLinkUrl(ctaButtonLink)} className="c-blogListingHero__showPostBtn btnPrimary btn--hoverMove">
              {ctaButton}
            </a>}
          </div>
          <StoryblokImage image={image} className="c-blogListingHero__image col-12 d-md-none col-iconity-36"/>
        </div>
      </div>
      <StoryblokImage image={image} className="c-blogListingHero__image d-none d-md-block"/>
    </div>
  )
}

export default BlogListingHero
