import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Headroom from 'react-headroom'
import classNames from 'classnames'

import DesktopMenu from '../navigation/components/DesktopMenu'
import MobileMenu from '../navigation/components/MobileMenu'
import StoryblokImage from '../shared/StoryblokImage'

type HeaderProps = {
  activeLanguage: string,
  pathname: string,
  isThankYou?: boolean,
}

const useHeaderDataFromStoryblok = (lang: string = 'default') => {
  const queryResult = useStaticQuery(
    graphql`
    query {
      allStoryblokEntry(filter: {name: {eq: "top-navigation"}}) {
        edges {
          node {
            content
            lang
          }
        }
      }
    }
  `
  );

  const edgeForLang = queryResult.allStoryblokEntry.edges
    .find(edge => edge.node.lang === lang);

  return edgeForLang ? JSON.parse(edgeForLang.node.content) : undefined;
};

const Header = ({ activeLanguage, pathname, isThankYou }: HeaderProps) => {
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const content = useHeaderDataFromStoryblok(activeLanguage)

  const handleHamburgerClick = () => {
    setMenuVisibility(!isMenuVisible)
  }

  const renderHamburgerClassNames = () => {
    return classNames({
      'header__hamburger': true,
      'header__hamburger--open': isMenuVisible,
      'd-xl-none': true,
    })
  }

  return (
    <header className="header">
      <Headroom className={isMenuVisible ? 'headroom--forceWhiteBg' : ''}>
        <div className="container">
          <div className="row">
            <div className="header__content col-12 d-flex justify-content-between align-items-center">

              {content?.logoLink.cached_url && (
                <a href={content?.logoLink.cached_url} className="header__logo">
                  <StoryblokImage image={content?.logo} />
                </a>
              )}

              <DesktopMenu
                content={content}
                pathname={pathname}
                activeLanguage={activeLanguage}
                isThankYou={isThankYou}
              />

              <div className={renderHamburgerClassNames()} onClick={() => handleHamburgerClick()}>
                <span />
                <span />
                <span />
              </div>

            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="col-12">
                <MobileMenu
                  isMenuVisible={isMenuVisible}
                  content={content}
                  pathname={pathname}
                  activeLanguage={activeLanguage}
                  isThankYou={isThankYou}
                />
              </div>
            </div>
          </div>
        </div>
      </Headroom>
    </header>
  )
}

export default Header
