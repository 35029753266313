import React from "react"

import PositionItem from "./PositionItem"

import { JobDescriptionNodeType } from "../../types"

type PositionsListProps = {
  hotPositions: JobDescriptionNodeType[]
  otherPositions: JobDescriptionNodeType[]
}

const PositionsList = ({
  hotPositions,
  otherPositions,
}: PositionsListProps) => {
  const hotJobs = hotPositions?.map((hotJob, i) => (
    <PositionItem key={`hot-job-${i}`} hot job={hotJob} />
  ))

  const otherJobs = otherPositions?.map((job, i) => (
    <PositionItem key={`job-${i}`} job={job} />
  ))

  return (
    <div className="c-positionsList">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xxl-9 col-xxxl-8">
            <div className="c-positionsList__content d-flex flex-column align-items-center">
              {hotJobs}
              {otherJobs}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PositionsList
