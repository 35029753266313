import React from "react"

import StoryblokLink from "../shared/StoryblokLink"

import { HeaderType } from "../../types"
import { cutInLines } from "../contentEdit"

import carreerPerson from "../../assets/images/HP-find-a-job.png"

export type HeroSectionProps = {
  content: HeaderType
}

const HeroCareer = ({ content }: HeroSectionProps) => {
  return (
    <div className="c-hero__item d-flex flex-column flex-xl-row justify-content-end align-items-start align-items-xl-stretch h-100">
      <div className="c-hero__item-imagePlace">
        <img src={carreerPerson} alt="Find a job" />
        <div className="c-hero__item-topRightCorner"></div>
        <div className="c-hero__item-topLeftCorner"></div>
      </div>

      <div className="c-hero__item-content">
        <h2 className="c-hero__item-headline">{cutInLines(content.title)}</h2>

        <p className="c-hero__item-subHeadline">{content.text}</p>

        <a href={content.ctaTarget.cached_url} className="btnLearnMore">
          {content.ctaButton}
        </a>
      </div>
    </div>
  )
}

export default HeroCareer
