import React from "react"
import StoryblokImage from "../shared/StoryblokImage"
import { FindJobLogos } from "../../types"

type FindJobSelectionLogosProps = {
  logos: FindJobLogos[],
  belowLogoText: string,
}

const FindJobSelectionLogos = ({ logos, belowLogoText }: FindJobSelectionLogosProps) => {
  const renderLogos = logos.filter(q => !!q.content).map(logo => (
    <li className="c-findJobSelection__logoItem d-flex justify-content-center align-items-center"
        key={`find-job-logo-${logo.content.technologyLogo.filename}`}>
      <StoryblokImage image={logo.content.technologyLogo}/>
    </li>
  ))

  return (
    <div className="c-findJobSelection__logos text-center">
      <ul className="c-findJobSelection__logoContent">
        {renderLogos}
      </ul>
      <span className="c-findJobSelection__logos-more">{belowLogoText}</span>
    </div>
  )
}

export default FindJobSelectionLogos
