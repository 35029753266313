import React from 'react'

import { StoryblokImageType } from '../../types'

import StoryblokImage from '../shared/StoryblokImage'

type FooterTopProps = {
  slogan: string,
  logo: StoryblokImageType,
}

const FooterTop = ({ slogan, logo }: FooterTopProps) => {
  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="footer__top">
          <div className="footer__top-logo">
            <StoryblokImage image={logo} />
          </div>

          <p className="footer__top-slogan">
            {slogan}
          </p>
        </div>
      </div>
    </div>
  )
}

export default FooterTop
