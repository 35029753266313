import React from "react"

import Layout from "../components/layout/Layout"
import DetailHero from "../components/positionDetail/DetailHero"
import DetailText from "../components/positionDetail/DetailText"
import DetailFormSection from "../components/positionDetail/DetailFormSection"
import Crossroads from "../components/shared/Crossroads"
import {
  CtaBanner,
  ExpertiseGuarantorType,
  RecruiterCardType,
  SeoMetaType,
  StoryblokImageType,
} from "../types"
import { CandidateFormProps } from "../zohoForms/CandidateForm"

export type PositionDetailProps = {
  pathname: string
  origin: string
  seoMeta: SeoMetaType
  contractType: string
  expertiseGuarantor: ExpertiseGuarantorType
  image: StoryblokImageType
  location: string
  recruiterCard: RecruiterCardType
  salary: string
  text1: any
  text2: any
  text3: any
  text4: any
  textForm: string
  title: string
  title1: string
  title2: string
  title3: string
  title4: string
  formTitle: string
  reusableCtaBanner: { content: CtaBanner }
  jobApplicationForm: { content: CandidateFormProps }
  simplityServicesPosition: boolean
  accurityPosition: boolean
  hotPosition: boolean
  formSource: string
}

const PositionDetail = ({
  pathname,
  origin,
  seoMeta,
  contractType,
  expertiseGuarantor,
  image,
  location,
  recruiterCard,
  salary,
  text1,
  text2,
  text3,
  text4,
  textForm,
  title,
  title1,
  title2,
  title3,
  title4,
  formTitle,
  reusableCtaBanner,
  jobApplicationForm,
  formSource,
}: PositionDetailProps) => {
  return (
    <Layout
      pathname={pathname}
      origin={origin}
      headerFix
      lightGreyBg
      seoMeta={seoMeta}
    >
      <DetailHero
        title={title}
        location={location}
        contractType={contractType}
        salary={salary}
        image={image}
      />
      <DetailText
        section1Title={title1}
        section1Text={text1}
        section2Title={title2}
        section2Text={text2}
        section3Title={title3}
        section3Text={text3}
        section4Title={title4}
        section4Text={text4}
        recruiterCard={recruiterCard?.content}
        expertiseGuarantor={expertiseGuarantor?.content}
      />
      <DetailFormSection
        formTitle={formTitle}
        textForm={textForm}
        form={jobApplicationForm?.content}
        formSource={formSource}
      />
      {reusableCtaBanner && reusableCtaBanner.content ? (
        <Crossroads ctaBanner={reusableCtaBanner.content} />
      ) : null}
      {/* <Recommendation /> */}
    </Layout>
  )
}

export default PositionDetail
