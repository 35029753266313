import React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import StoryblokImage from "../shared/StoryblokImage"

import heroBg from "../../assets/images/team-wall.jpg"
import desk from "../../assets/images/team-desk.png"

import { StoryblokImageType } from "../types"

type CompanyJobsProps = {
  title: string
  titleText: any
  frameImage: StoryblokImageType
}

const CompanyJobsHero = ({
  title,
  titleText,
  frameImage,
}: CompanyJobsProps) => {
  const text = render(titleText)

  return (
    <section className="c-companyJobsHero">
      <img src={heroBg} alt="iconity" className="c-meetTeamHero__bg" />
      <img src={desk} alt="iconity" className="c-meetTeamHero__flowers" />

      <div className="container">
        <div className="row">
          <div className="col-xxl-1 col-xxxl-2" />

          <div className="col-xl-5 col-xxxl-4">
            <div className="c-companyJobsHero__content">
              <h1 className="pageHeading c-companyJobsHero__headline">
                {title}
              </h1>
              <div className="c-companyJobsHero__text">{text}</div>
            </div>
          </div>

          <div className="col-xl-6 col-xxl-4">
            <StoryblokImage
              image={frameImage}
              className="c-companyJobsHero__painting"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyJobsHero
