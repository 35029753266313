import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

type NothingFoundProps = {
  activeLanguage: string,
}

const use404DataOrFakeData = () => {
  try {
    const queryResult = useStaticQuery(
      graphql`
      query {
        allStoryblokEntry(filter: {name: {eq: "404"}}) {
          edges {
            node {
              content
              lang
            }
          }
        }
      }
    `
    );
    let errContent = {}
    const xxx = queryResult.allStoryblokEntry.edges.map(v => {
      errContent[`${v.node.lang}`] = JSON.parse(v.node.content)
    })

    return errContent
  } catch {
    console.error('Static Query for Top Navigation failed!!! Falling back to dummy data to populate navigation.');
    //return dummy404Data;
  }
};

const NothingFound = ({ activeLanguage }: NothingFoundProps) => {
  const content = use404DataOrFakeData()

  return (
    <div className="c-nothingFound">
      <div className="container">
        <div className="row">
          <div className="col-lg-3" />

          <div className="col-lg-8 col-xl-6">
            <div className="c-nothingFound__content">
              <h1 className="c-nothingFound__headline">
                404
              </h1>
              <p className="c-nothingFound__text">
                {content[activeLanguage]?.text}
              </p>
              <a href={content[activeLanguage]?.buttonLink.cached_url} className="btnSecondary btn--hoverMove">
                {content[activeLanguage]?.buttonLabel}
              </a>
            </div>
          </div>

          <div className="col-lg-1 col-xl-3" />
        </div>
      </div>
    </div>
  )
}

export default NothingFound
