import React from "react"

import Layout from "../components/layout/Layout"
import NothingFound from "../components/404/NothingFound"
import { getLangFromPathname } from "../utils"

type ErrorPageProps = {
  pathname: string,
  origin: string,
}

function ErrorPage({ pathname, origin }: ErrorPageProps) {
  const activeLanguage = getLangFromPathname(pathname);

  return (
    <Layout pathname={pathname} origin={origin} headerFix>
      <NothingFound activeLanguage={activeLanguage} />
    </Layout>
  )
}

export default ErrorPage
