import React from "react"

import Layout from '../components/layout/Layout'
import FindJobHero from '../components/findJob/FindJobHero'
import FindJobSelection from '../components/findJob/FindJobSelection'
import Crossroads from '../components/shared/Crossroads'

import { FindJobHeader, FindJobLogos, FindJobCards, CtaBanner, SeoMetaType } from "../types"

type FindJobPageProps = {
  pathname: string,
  origin: string,
  header: Array<FindJobHeader>,
  tilesHeader: string,
  tilesText: string,
  titleLeft: string,
  titleRight: string,
  logosLeft: Array<FindJobLogos>,
  logosRight: Array<FindJobLogos>,
  belowLogoText: string,
  cards: Array<FindJobCards>,
  ctaBanner: Array<CtaBanner>,
  seoMeta: SeoMetaType,
}

function FindJobPage({
  pathname,
  origin,
  header,
  tilesHeader,
  tilesText,
  titleLeft,
  titleRight,
  logosLeft,
  logosRight,
  belowLogoText,
  cards,
  ctaBanner,
  seoMeta
}: FindJobPageProps) {
  return (
    <Layout pathname={pathname} origin={origin} seoMeta={seoMeta} headerFix lightGreyBg contentClass="findJobPage" >
      <FindJobHero header={header[0]} />
      <FindJobSelection
        tilesHeader={tilesHeader}
        tilesText={tilesText}
        titleLeft={titleLeft}
        titleRight={titleRight}
        logosLeft={logosLeft}
        logosRight={logosRight}
        belowLogoText={belowLogoText}
        cards={cards}
      />
      <Crossroads ctaBanner={ctaBanner[0]} />
    </Layout>
  )
}

export default FindJobPage


