const availableLanguages = ["en", "de"]

export const getLangFromPathname = (pathname: string) => {
  const language = pathname?.split("/")[1].replace("/", "")
  return availableLanguages.includes(language) ? language : "default"
}

export const englishGerman = (germanUrl: string, pathname: string) => {
  const whitepaperTitle = pathname.split("/").pop().replace("/", "")

  let finalUrl
  if (whitepaperTitle) {
    finalUrl = germanUrl.concat(whitepaperTitle).concat("/")
  } else if (whitepaperTitle === "") {
    const slashedBlogTitle = pathname
      .split("/")
      [pathname.split("/").length - 2].concat("/")

    slashedBlogTitle ? (finalUrl = germanUrl.concat(slashedBlogTitle)) : "/"
  } else {
    finalUrl = "/"
  }

  return finalUrl
}

export const germanEnglish = (englishUrl: string, pathname: string) => {
  var position = pathname.split("/").pop().replace("/", "")

  let finalUrl
  if (position) {
    finalUrl = englishUrl.concat(position).concat("/")
  } else if (position === "") {
    const slashedPosition = pathname
      .split("/")
      [pathname.split("/").length - 2].concat("/")

    slashedPosition ? (finalUrl = englishUrl.concat(slashedPosition)) : "/"
  } else {
    finalUrl = "/"
  }

  return finalUrl
}

export const SlugList = {
  ["/de/lernen-sie-unser-team-kennen/"]: "/meet-the-team/",
  ["/de/lernen-sie-unser-team-kennen"]: "/meet-the-team/",
  ["/meet-the-team/"]: "/de/lernen-sie-unser-team-kennen/",
  ["/meet-the-team"]: "/de/lernen-sie-unser-team-kennen/",
  ["/de/kontaktieren-sie-uns/"]: "/get-in-touch/",
  ["/de/kontaktieren-sie-uns"]: "/get-in-touch/",
  ["/get-in-touch/"]: "/de/kontaktieren-sie-uns/",
  ["/get-in-touch"]: "/de/kontaktieren-sie-uns/",
  ["/de/blog/"]: "/blog/",
  ["/de/blog"]: "/blog/",
  ["/blog/"]: "/de/blog/",
  ["/blog"]: "/de/blog/",
  ["/de/stelle-finden/"]: "/find-a-job/",
  ["/de/stelle-finden"]: "/find-a-job/",
  ["/find-a-job/"]: "/de/stelle-finden/",
  ["/find-a-job"]: "/de/stelle-finden/",
  ["/find-a-job/why-we-are-different/"]: "/de/stelle-finden/was-uns-ausmacht/",
  ["/find-a-job/why-we-are-different"]: "/de/stelle-finden/was-uns-ausmacht/",
  ["/de/stelle-finden/was-uns-ausmacht/"]: "/find-a-job/why-we-are-different/",
  ["/de/stelle-finden/was-uns-ausmacht"]: "/find-a-job/why-we-are-different/",
  ["/de/stelle-finden/offene-stellenangebote/"]: "/find-a-job/open-positions/",
  ["/de/stelle-finden/offene-stellenangebote"]: "/find-a-job/open-positions/",
  ["/find-a-job/open-positions/"]: "/de/stelle-finden/offene-stellenangebote/",
  ["/find-a-job/open-positions"]: "/de/stelle-finden/offene-stellenangebote/",
  ["/find-a-specialist/"]: "/de/finden-sie-einen-spezialisten/",
  ["/find-a-specialist"]: "/de/finden-sie-einen-spezialisten/",
  ["/de/finden-sie-einen-spezialisten/"]: "/find-a-specialist/",
  ["/de/finden-sie-einen-spezialisten"]: "/find-a-specialist/",
  ["/find-a-specialist/our-process/"]:
    "/de/finden-sie-einen-spezialisten/unser-prozess/",
  ["/find-a-specialist/our-process"]:
    "/de/finden-sie-einen-spezialisten/unser-prozess/",
  ["/de/finden-sie-einen-spezialisten/unser-prozess/"]:
    "/find-a-specialist/our-process/",
  ["/de/finden-sie-einen-spezialisten/unser-prozess"]:
    "/find-a-specialist/our-process/",
  ["/de/ressourcen/"]: "/resources/",
  ["/de/ressourcen"]: "/resources/",
  ["/resources/"]: "/de/ressourcen/",
  ["/resources"]: "/de/ressourcen/",
  ["/de/bedingungen-und-bestimmungen/"]: "/terms-conditions/",
  ["/de/bedingungen-und-bestimmungen"]: "/terms-conditions/",
  ["/terms-conditions/"]: "/de/bedingungen-und-bestimmungen/",
  ["/terms-conditions"]: "/de/bedingungen-und-bestimmungen/",
  ["/de/impressum/"]: "/impressum/",
  ["/de/impressum"]: "/impressum/",
  ["/impressum/"]: "/de/impressum/",
  ["/impressum"]: "/de/impressum/",
  ["/de/"]: "/",
  ["/de"]: "/",
  ["/"]: "/de/",
}

export const getTranslatedUrl = (
  pathname,
  activeLanguage,
  desiredLanguage,
  isThankYou
) => {
  if (activeLanguage === desiredLanguage) return pathname

  if (typeof SlugList[pathname] === "undefined") {
    switch (true) {
      case pathname.includes("/find-a-job/open-positions/") && !isThankYou:
        return englishGerman(
          "/de/stelle-finden/offene-stellenangebote/",
          pathname
        )

      case pathname.includes("/de/stelle-finden/offene-stellenangebote/") &&
        !isThankYou:
        return germanEnglish("/find-a-job/open-positions/", pathname)

      case pathname.includes("/de/blog/"):
        return germanEnglish("/blog/", pathname)

      case pathname.includes("/blog/"):
        return englishGerman("/de/blog/", pathname)

      case pathname.includes("/de/whitepaper/") && !isThankYou:
        return germanEnglish("/whitepaper/", pathname)

      case pathname.includes("/whitepaper/") && !isThankYou:
        return englishGerman("/de/whitepaper/", pathname)

      case pathname.includes("/de/webinar/on-demand/") && !isThankYou:
        return germanEnglish("/webinar/on-demand/", pathname)

      case pathname.includes("/webinar/on-demand/") && !isThankYou:
        return englishGerman("/de/webinar/on-demand/", pathname)

      case pathname.includes("/de/webinar/") && !isThankYou:
        return englishGerman("/webinar/", pathname)

      case pathname.includes("/webinar/") && !isThankYou:
        return englishGerman("/de/webinar/", pathname)

      default:
        return "/"
    }
  }

  return SlugList[pathname]
}
