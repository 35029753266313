import React from "react"
import GetInTouchForm, { GetInTouchFormProps } from "../../zohoForms/GetInTouchForm"

type GetFormProps = {
  headline: string,
  subHeadline: string,
} & GetInTouchFormProps;

const GetForm = ({
  headline,
  subHeadline,
  ...formProps
}: GetFormProps) => {
  return (
    <div className="c-getForm">
      <h1 className="c-getContact__headline d-xl-none">
        {headline}
      </h1>
      <p className="c-getContact__text d-xl-none">
        {subHeadline}
      </p>
      <div className="c-getForm__box">
        <GetInTouchForm
          {...formProps}
        />
      </div>
    </div>
  )
}

export default GetForm
