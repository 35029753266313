import React from 'react'
import heroBg from '../../assets/images/wall.jpg'
import painting from '../../assets/images/painting.png'
import flowers from '../../assets/images/flowers.png'

import { HeaderType } from '../../types'

type PaintingHeroProps = {
  content: HeaderType,
}

const PaintingHero = ({ content }: PaintingHeroProps) => {
  return (
    <section className="c-howWeWorkHero">
      <img src={heroBg} alt="iconity" className="c-howWeWorkHero__bg"/>
      <img src={flowers} alt="iconity" className="c-howWeWorkHero__flowers"/>

      <div className="container">
        <div className="row">
          <div className="col-xl-1 col-xxxl-2" />

          <div className="col-xl-6 col-xxl-5 col-xxxl-4">
            <div className="c-howWeWorkHero__content">
              <h1 className="pageHeading c-howWeWorkHero__headline">
                {content?.title}
              </h1>
              <div className="c-howWeWorkHero__text">
                <p>
                  {content?.text}
                </p>
              </div>
              {content?.ctaTarget.cached_url && (
                <a href={content.ctaTarget.cached_url} className="btnPrimary btn--hoverMove">
                  {content.ctaButton}
                </a>
              )}
            </div>
          </div>

          <div className="col-xl-5 col-xxl-4">
            <img src={painting} className="c-howWeWorkHero__painting" alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PaintingHero
