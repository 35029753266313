import React, { SyntheticEvent } from "react"

export type RadioProps = {
  name: string;
  label: string;
  value: string;
  setValue: (name: string, value: string) => void;
  error?: string;
  clearError?: (name: string) => void;
  id: string,
}

const Radio = ({ id, name, label, value, setValue, error, clearError }: RadioProps) => {
  const hasError = !!error;

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (error && clearError) {
      clearError(name);
    }
    setValue(name, value);
  };

  return (
    <div className={`radio ${hasError ? 'radio--error' : ''}`}>
      <input
        id={id}
        name={name}
        type="radio"
        onChange={handleChange}
        className="radio__input"
      />
      <label htmlFor={id} className={'radio__label'}>
        {label}
      </label>
      {hasError ?
        <span className={'radio__error'}>
          {error}
        </span>
        : null
      }
    </div>
  );
}

export default Radio;
