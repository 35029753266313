import React from 'react'

import DetailPatronCard from './DetailPatronCard'

import LinkedinLogo from '../../assets/images/icons/linkedin.svg'
import cardPhoto from '../../assets/images/recruiter-card2.jpg'

import { RecruiterCardContentType, ExpertiseGuarantorContentType } from '../../types'

type DetailPatronsProps = {
  recruiterCard: RecruiterCardContentType,
  expertiseGuarantor: ExpertiseGuarantorContentType,
}

const DetailPatrons = ({ recruiterCard, expertiseGuarantor }: DetailPatronsProps) => {
  return (
    <div className="c-detailPatrons d-flex flex-column flex-md-row flex-xl-column justify-content-between align-items-center align-items-md-stretch">
      <DetailPatronCard content={recruiterCard} />
      <DetailPatronCard content={expertiseGuarantor} />
    </div>
  )
}

export default DetailPatrons
