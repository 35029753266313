import React  from "react"
import Accordion from "react-bootstrap/Accordion"
import classNames from "classnames"
import SubmenuComponents from "./SubmenuComponents"
import ArrowIcon from "../../../assets/images/icons/arrow-right-big.svg"
import { SubmenuItemType } from "../../../types"

type MobileSubmenuProps = {
  items: SubmenuItemType[]
  label: string
  onMenuItemOpen: () => void
  isExpanded: boolean
}

const MobileSubMenu = ({ items, label, isExpanded, onMenuItemOpen }: MobileSubmenuProps) => {

  const submenuItems = items?.map((item, i) =>
    React.createElement(SubmenuComponents(item.component), {
      key: i,
      ...item,
    })
  )

  return (
    <Accordion
      className={classNames('topNav__mobileSubmenu', {'topNav__mobileSubmenu--expanded': isExpanded})}
      activeKey={isExpanded ? '0' : ''}
      onSelect={() => onMenuItemOpen()}
    >
      <Accordion.Toggle eventKey="0" className="topNav__mobileSubmenuToggler topNav__menuItemLabel">
        {label}
        <ArrowIcon />
      </Accordion.Toggle>

      <Accordion.Collapse eventKey="0">
        <ul className="topNav__mobileSubmenuContent">
          {submenuItems}
        </ul>
      </Accordion.Collapse>
    </Accordion>
  )
}

export default MobileSubMenu
