import React from 'react'

import { cutInLines } from '../contentEdit'

export type HeroTopProps = {
  headline: string,
  subHeadline: string,
}

const HeroTop = ({ headline, subHeadline }: HeroTopProps) => {
  return (
    <>
      <h1 className="c-hero__headline text-center">
        {cutInLines(headline)}
      </h1>

      <p className="c-hero__subHeadline text-center">
        {cutInLines(subHeadline)}
      </p>
    </>
  )
}

export default HeroTop
