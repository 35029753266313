import React from "react"
import { ThreeElementBlockType } from "../../types"
import StoryblokImage from "./StoryblokImage"

const ThreeElementBlock = ({ icon, paragraph, title }: ThreeElementBlockType) => {

  return (
    <div className="c-threeBlock col-12 col-sm-6 col-lg-4">
      <StoryblokImage image={icon} className="c-threeBlock__icon" />
      <h3 className="c-threeBlock__title">{title}</h3>
      <p className="c-threeBlock__paragraph">{paragraph}</p>
    </div>
  )
}

export default ThreeElementBlock
