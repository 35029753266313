import React from "react"
import { render } from "storyblok-rich-text-react-renderer"
import { BlogAuthorType } from "../../types"
import StoryblokImage from "../shared/StoryblokImage"

type BlogPostAuthorBioProps = {
  authors: BlogAuthorType[]
}

const BlogPostAuthorBio = ({ authors }: BlogPostAuthorBioProps) => {

  return (
    <section className="container">
      <div className="row">
        <div className="col-12 col-xl-7 col-xxl-6 c-blogPostAuthorBio">
          <div className="c-blogPostAuthorBio__content">
            {authors.map(author => (
              <div className="c-blogPostAuthorBio__wrapper" key={author.name}>
                <StoryblokImage
                  image={author.profilePhoto}
                  className={"c-blogPostAuthorBio__photo"}
                />
                <div className="c-blogPostAuthorBio__nameAndBioWrapper">
                  <div className="c-blogPostAuthorBio__name">{author.name}</div>
                  <div className="c-blogPostAuthorBio__bio">{author.bio}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default BlogPostAuthorBio
