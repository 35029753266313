import React from 'react'

import { FooterLink } from '../../types'

type FooterBottomProps = {
  copyright?: string,
  bottomLinks: Array<FooterLink>,
}

const FooterBottom = ({ copyright, bottomLinks }: FooterBottomProps) => {
  const links = bottomLinks?.map((link, i) => (
    <li className="footer__bottom-listItem" key={`footer-bottom-link-${i}`}>
      <a href={link.link.cached_url} target="_blank" rel="noopener noreferrer">
        {link.label}
      </a>
    </li>
  ))

  return (
    <div className="row">
      <div className="col-12">
        <div className="footer__bottom d-flex flex-column flex-xl-row justify-content-between align-items-xl-center">
          <span className="footer__copy order-2 order-xl-1">
            {copyright}
          </span>
          <ul className="footer__bottom-list d-flex flex-wrap order-1 order-xl-2">
            {links}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FooterBottom
